module.exports = {
    language: "波兰语",


    setting: {
        index: {
            title: "Ustawienia",
            problem: "Informacje zwrotne o problemie",
            order: "Rekord zamówień",
            notice: "Powiadomienie",
            language: "Język",
            clear: "Wyczyść pamięć podręczną",
            clearTip: "Czy chcesz potwierdzić \n wyczyszczenie pamięci podręcznej?",
            confirm: "Potwierdź",
            cancel: "Anuluj",
        },
        language: {
            title: "język",
            langList: ["Chiński uproszczony", "tradycyjne chińskie", "język angielski", "arabski", "Polski", "perski", "duński", "Niemiecki", "Rosyjski", "Francuski", "Holenderski", "Czech", "rumuński", "portugalski", "język japoński", "tajski", "turecki", "hiszpański", "grecki", "Włoski", "wietnamski", "hinduski"],
        },
        order: {
            title: "Rejestr zamówień",
            success: "Uzyskano pomyślnie",
            unpaid: "Nieopłacone",
            paid: "Opłacone",
            expired: "Wygasłe",
            refunded: "Zwrócone",
            pTime: "Czas płatności",
            eTime: "Czas wygaśnięcia",
            pMethod: "Metoda płatności",
            pObject: "Przedmiot płatności",
            oNumber: "Numer zamówienia",
            pType: "Rodzaj pakietu",
            oTime: "Czas uzyskania",
            watch: "Obejrzyj numer IMEI",
            delete: "Usuń zamówienie",
            refund: "Poproś o zwrot",
            empty: "Brak rekordów",
            grade: "Poziom pakietu",
            package: "Stan pakietu",
            use: "Stan użycia",
            remaining: "Pozostała ilość pakietu",
            basic: "Zwykły",
            pro: "Zaawansowany",
            refundAmount: "Kwota zwrotu"
        },
        problem: {
            title: "Opinie i problemy",
            feedback: "Rodzaj opinii",
            please: "Proszę wybrać",
            content: "Treść",
            improve: "Prosimy o wpisanie swoich sugestii, takich jak wymagania funkcjonalne, skargi na produkt itp. Będziemy ciężko pracować, aby się poprawić",
            upload: "Upload obrazu",
            submit: "Prześlij",
            general: "Ogólne opinie",
            purchase: "Związane z zakupem",
            bug: "Zgłoszenie błędu",
            suggestion: "Sugestia",
            other: "Inne",
        },
        notice: {
            title: "Powiadomienie",
            type: "Typ powiadomienia: Odpowiedź systemu",
            time: "Czas powiadomienia: "
        }
    },
    subscription: {
        jarvis: {
            funcList: ["AI Pytania i Odpowiedzi", "AI Rysowanie", "Podstawowa wersja Inteligentnej AI", "Zaawansowana Inteligentna AI", "Wielomodowy wejście i wyjście", "Bardziej szczegółowe Pytania i Odpowiedzi", "Inteligentniejsze modele", "Szybsza odpowiedź"],
            times: "Raz",
            days: "Dni",
            more: "Bardziej zaawansowana AI",
            custom: "Dostosowana Inteligentna AI",
            delay: "Może wystąpić niewielkie opóźnienie w zaksięgowaniu twojego konta. Proszę poczekać, a następnie wyjść z aplikacji zegarka i ponownie się do niej zalogować.",
            member: "Porównanie członków",
            packageName: "Nazwa pakietu",
            unlimited: "Bezkresny",
            month: "Miesiąc",
            },
        index: {
            nav: {
                l: "Pro",
                r: "Czas",
                language: "Język",
                notice: "Powiadomienie",
                feedback: "Opinie"
            },
            proBox: {
                expire: {
                    title: "Data wygaśnięcia",
                    date: "Nie subskrybowano jeszcze",
                },
                packTip: "Mamy różne miesięczne pakiety",
                packList: ["Supreme Subscription", "Basic Subscription", "Normal subscription"],
                benefit: {
                    origin: "Cena oryginalna: ",
                    remaining: "Pozostało",
                    title: "Korzyści subskrypcji",
                    value: "wartość",
                    benefitList: [
                        { labelTime: "Nieograniczone ilości", labelSave: "Zapis obrazu", labelPer: "Pełne uprawnienia" },
                        { labelTime: "300 razy", labelSave: "Przechowywanie rekordów", labelPer: "Podstawowe uprawnienia" },
                        { labelTime: "100 razy", labelSave: "Przechowywanie rekordów", labelPer: "Niskie przywileje" },
                    ],
                    desc: "$3.99/miesiąc zawiera 100 bezpłatnych użyć, $9.99/miesiąc zawiera 300 bezpłatnych użyć.",
                    tip: "Może wystąpić niewielkie opóźnienie w kredytowaniu liczby razy na Twoje konto. Proszę poczekać chwilę, zamknąć aplikację zegarka i ponownie ją otworzyć."
                },
            },
            timeBox: {
                packTip: "Mamy różne miesięczne pakiety",
                packList: ["Pakiet rabatowy dla nowych użytkowników", "Pakiet 50 razy", "Pakiet 100 razy", "Pakiet 300 razy"],
                times: {
                    title: "Rola Times",
                    timesList: ["Można używać do rysowania", "Dostępny do Q&A GPT", "Używane do wróżenia horoskopu", "Można używać do nauki", "Używane do rozpoznawania obiektów"],
                    desc: "50 razy użycia za $2.99, 100 razy użycia za $4.99, 300 razy użycia za $12.99. Pakiet na 50 razy za $0.99 jest przeznaczony dla nowych użytkowników i można go kupić tylko raz (dostępny w ciągu 24 godzin)",
                }
            },
            staticBox: {
                confirm: "Potwierdź",
                privacy: "Prywatność",
                terms: "Warunki",
                refund: "Zwrot",
                cancel: "Anuluj w każdej chwili",
                funcTitle: "Rozwijaj swoją kreatywność z doświadczeniem PRO",
                function: "Funkcja",
                free: "Darmowe",
                pro: "Pro",
                funcList: ["Ilość ważna przez jeden dzień", "10 razy Rysuj/Rozmawiaj (Trzy szanse na aktywację)", "Subskrybuj raz na 30 dni", "Więcej razy do użycia", "Obrazy zachowane na zawsze", "Liczba razy, które możesz zakupić"],
                details: "Szczegóły",
            },
            dialog: {
                error: "Płatność jest niedostępna. Proszę zeskanować kod QR na swoim zegarku.",
                unbind: "Zegarek został powiązany z metodą płatności. Czy chcesz go odpiąć?",
                beforePay: "Czy kontynuować płatność przy użyciu rachunku bieżącego",
                renewal: "Określa, czy włączyć automatyczne odnawianie",
                confirm: "Potwierdź",
                cancel: "Anuluj",
                change: "zmienić/związać",
                beforeConsent: "Zasubskrybowałeś ten produkt",
            }
        },
        NewIndex:{
            l:"Podstawowy",
            r:"Pro",
            proBox:{
                benefit:{
                    title:"Pakiet zawiera",
                    benefitList:[
                        {labelTime: "Otrzymaj czasy",labelSave: "normalne czasy"},
                        {labelTime: "Otrzymaj czasy",labelSave: "czasy Pro"},
                        {labelTime: "Odblokowanie funkcji",labelSave: "Odblokuj wszystkie normalne funkcje"},
                        {labelTime: "Odblokowanie funkcji",labelSave: "Odblokuj wszystkie funkcje premium"},
                    ]
                }
            },
            funcList: ["Q&A", "Rysowanie AI", "Podstawowa wersja Smart AI", "Zaawansowane Smart AI", "Tekst na mowę", "Bardziej szczegółowe odpowiedzi", "Szybsze odpowiedzi"],
            confirm:'Ulepsz teraz',
            dialog:{
                beforePay: "Subskrybujesz zwykłe członkostwo. Jeśli zdecydujesz się na zakup członkostwa premium, zostanie Ci zwrócona kwota zgodnie z faktycznym użyciem członkostwa zwykłego. Czy chcesz kontynuować?",
            }
        },
        unsubscribe: {
            title: "Ciągła Subskrypcja",
            subTitle: "Subskrybowanie",
            unsubscribe: "Anuluj subskrypcję", 
            subscribeTime: "Czas subskrypcji",
        },
        refund: {
            title: "Wniosek o zwrot kosztów",
            number: "Numer zamówienia",
            content: "Proszę o wyjaśnienie powodu zwrotu pieniędzy",
            empty: "Nie można przesłać, wymagane pola są puste.",
        },
        order: {
            number: "Numer zamówienia",
            name: "Nazwa zamówienia",
            times: "Czasy składania zamówienia",
            validity: "Ważność",
            buy: "Kup teraz",
            days: "Liczba dni",
            unlock: "Odblokuj wszystkie inteligentne AI",
            text: "Tekst na mowę",
            tips: "To jest produkt subskrypcyjny. Po zakupie subskrypcja rozpoczyna się domyślnie.",
        },
        zhzn: {
            title: "Uprawnienia członkostwa",
            t1: "Nieograniczone pytania i odpowiedzi",
            d1: "Nieograniczone korzystanie z funkcji czatu pytań i odpowiedzi podczas członkostwa",
            t2: "Rysowanie AI",
            d2: "Odblokuj uprawnienia do rysowania AI, możesz wykonywać funkcję rysowania tekstu",
            t3: "Ustawienia wybierania",
            d3: "Pokrętło można ustawić zgodnie z wynikami rysowania AI",
        }
    },
    payment: {
        index: {
            contact: "Dane kontaktowe",
            please: "Proszę podać swój adres e-mail",
            email: "Potwierdzenie płatności zostanie wysłane na ten adres e-mail",
            name: "Imię na karcie",
            firstName: "Imię",
            lastName: "Nazwisko",
            confidence: "Nie przechowujemy żadnych Twoich danych, proszę wprowadzać je z pełnym zaufaniem",
            Card: "Informacje o karcie",
            confirm: "Potwierdzając subskrypcję, zezwalasz Jarvis na obciążenie Twojej karty za przyszłe płatności zgodnie z ich warunkami. Możesz anulować swoją subskrypcję w dowolnym momencie.",
            powered: "Obsługiwane przez Stripe",
            terms: "Warunki",
            privacy: "Prywatność",
        }
    },
    policy: {
        title: {
            terms: "Warunki",
            about: "O nas",
            service: "Warunki usługi",
            privacy: "Polityka prywatności",
            refund: "Polityka zwrotów",
            us: "O nas",
        },
        service: {
            section1: `<p>Strona internetowa znajdująca się pod adresem https://aiwatchstore.org/ jest dziełem chronionym prawami autorskimi należącym do HONG KONG ADFLASH MEDIA LIMITED. Niektóre funkcje Strony mogą podlegać dodatkowym wytycznym, warunkom lub zasadom, które będą zamieszczane na Stronie w związku z takimi funkcjami.</p>
<p>Wszystkie takie dodatkowe warunki, wytyczne i zasady są włączone przez odniesienie do niniejszych Warunków.</p>
<p>"Niniejsze Warunki użytkowania" described the legally binding terms and conditions that oversee your use of the Site. "LOGUJĄC SIĘ DO SERWISU, ZGADZASZ SIĘ NA TE WARUNKI" and you represent that you have the authority and capacity to enter into these Terms. "POWINIENEŚ MIEĆ CO NAJMNIEJ 18 LAT, ABY UZYSKAĆ DOSTĘP DO SERWISU. JEŚLI NIE ZGADZASZ SIĘ ZE WSZYSTKIMI POSTANOWIENIAMI TYCH WARUNKÓW, NIE LOGUJ SIĘ DO SERWISU I NIE UŻYWAJ GO."</p>
<p>Warunki te wymagają korzystania z arbitrażu zgodnie z Sekcją 10.2 na zasadzie indywidualnej w celu rozstrzygania sporów oraz ograniczają środki zaradcze dostępne dla ciebie w przypadku sporu.</p>
<h2>Dostęp do Serwisu</h2>
<p><strong>Podlega niniejszym Warunkom.</strong> Udzielamy Ci niezbywalnej, niewyłącznej, odwołalnej, ograniczonej licencji na dostęp do Serwisu wyłącznie do własnego, osobistego, niekomercyjnego użytku.</p>
<p>Zastrzegamy sobie prawo do zmiany, zawieszenia lub zaprzestania działania Serwisu z powiadomieniem lub bez powiadomienia. Akceptujesz, że nie będziemy ponosić odpowiedzialności wobec Ciebie ani żadnej strony trzeciej za jakiekolwiek zmiany, przerwy lub zakończenie działania Serwisu lub jakiejkolwiek jego części.</p>
<p><strong>Brak wsparcia ani konserwacji.</strong> Zgadzasz się, że nie będziemy zobowiązani do udzielania Ci jakiegokolwiek wsparcia w związku z Serwisem.</p>
<p>Z wyjątkiem wszelkich Treści Użytkownika, które możesz dostarczyć, jesteś świadomy, że wszystkie prawa własności intelektualnej, w tym prawa autorskie, patenty, znaki towarowe i tajemnice handlowe w Serwisie i jego treściach, należą do nas lub naszych dostawców. Należy zauważyć, że niniejsze Warunki i dostęp do Serwisu nie przyznają Ci żadnych praw, tytułów ani interesów w jakichkolwiek prawach własności intelektualnej, z wyjątkiem ograniczonych praw dostępu wyrażonych w Sekcji 2.1. My i nasi dostawcy zastrzegamy sobie wszelkie prawa nieprzyznane w niniejszych Warunkach.</p>`,
            section2: `<h2>Warunki subskrypcji</h2>
<p>Witamy w naszej usłudze subskrypcji (dalej zwanej "Usługą"). Usługa ta jest świadczona przez HONG KONG ADFLASH MEDIA LIMITED (dalej zwane "my" lub "Firma"). Korzystając z naszej usługi lub subskrybując ją, zgadzasz się na przestrzeganie niniejszych Warunków subskrypcji (dalej zwanych "Warunkami").</p>
<p>Oferujemy subskrypcyjne usługi, w tym ekskluzywne przydziały użytkowników. Konkretne oferty usług oraz opłaty mogą ulegać zmianom w dowolnym czasie i będą aktualizowane poprzez powiadomienia e-mailowe lub na naszej stronie internetowej.</p>
<p>Szczegóły dotyczące opłat za subskrypcję, metod płatności i cykli płatności znajdują się na stronie z cennikiem. Konta z zaległymi płatnościami będą miały swoje płatne usługi tymczasowo zawieszone.</p>
<p>Wszystkie opłaty subskrypcyjne są bezzwrotne, chyba że wymaga tego prawo lub według naszego uznania.</p>
<p>Możesz anulować swoją subskrypcję w dowolnym momencie. Po anulowaniu, nadal będziemy świadczyć usługi do końca bieżącego okresu subskrypcji, ale żadne refundacje za już opłacone opłaty nie będą wydawane.</p>
<p>Zastrzegamy sobie również prawo do jednostronnego zakończenia lub zawieszenia Twojej usługi w następujących okolicznościach:</p>
<p>- Naruszenie niniejszych Warunków</p>
<p>- Brak płatności za opłaty subskrypcyjne</p>
<p>Dołożymy wszelkich starań, aby świadczyć usługi najwyższej jakości, ale nie udzielamy żadnych wyraźnych ani dorozumianych gwarancji dotyczących dostępności, dokładności lub kompletności usługi.</p>`,
            section3: `<h2>Inni Użytkownicy</h2>
<p><strong>Inni Użytkownicy.</strong> Każdy użytkownik Serwisu jest samodzielnie odpowiedzialny za jakiekolwiek i wszystkie swoje własne Treści Użytkownika. Ponieważ nie kontrolujemy Treści Użytkownika, przyjmujesz do wiadomości i zgadzasz się, że nie ponosimy odpowiedzialności za jakiekolwiek Treści Użytkownika, niezależnie od tego, czy są one dostarczane przez Ciebie, czy przez innych. Zgadzasz się, że nie będziemy ponosić odpowiedzialności za jakiekolwiek straty lub szkody poniesione w wyniku takich interakcji. Jeśli powstanie spór między Tobą a jakimkolwiek użytkownikiem Serwisu, nie jesteśmy zobowiązani do angażowania się.</p>
<p>Zwalniasz i na zawsze zwalniasz nas oraz naszych funkcjonariuszy, pracowników, agentów, następców i cesjonariuszy z wszelkich sporów, roszczeń, kontrowersji, żądań, praw, obowiązków, zobowiązań, działań i przyczyn działań wszelkiego rodzaju i natury, które powstały lub powstają bezpośrednio lub pośrednio z Serwisu lub są z nim związane. Jeśli jesteś mieszkańcem Kalifornii, niniejszym zrzekasz się sekcji 1542 kalifornijskiego kodeksu cywilnego w związku z powyższym, która brzmi: "ogólne zwolnienie nie obejmuje roszczeń, których wierzyciel nie zna ani nie podejrzewa, że istnieją na jego korzyść w chwili wykonywania zwolnienia, które, jeśli byłyby mu znane, musiałyby znacząco wpływać na jego rozliczenie z dłużnikiem."</p>
<p><strong>Cookies i Web Beacons.</strong> Jak każda inna witryna, używamy 'cookies'. Te pliki cookie są używane do przechowywania informacji, w tym preferencji odwiedzających oraz stron na witrynie, które odwiedzający odwiedził lub uzyskał do nich dostęp. Informacje te są używane do optymalizacji doświadczeń użytkowników poprzez dostosowanie zawartości naszej strony internetowej w oparciu o typ przeglądarki odwiedzającego i/lub inne informacje.</p>
<h2>Zrzeczenia Odpowiedzialności</h2>
<p>Serwis jest dostarczany na zasadzie "tak jak jest" i "według dostępności", a my i nasi dostawcy wyraźnie zrzekamy się wszelkich gwarancji i warunków jakiegokolwiek rodzaju, zarówno wyraźnych, jak i dorozumianych, w tym wszystkich gwarancji lub warunków przydatności handlowej, przydatności do określonego celu, tytułu, cichego korzystania, dokładności lub nienaruszalności. My i nasi dostawcy nie gwarantujemy, że serwis spełni Twoje wymagania, będzie dostępny w sposób nieprzerwany, terminowy, bezpieczny lub bezbłędny, lub będzie dokładny, niezawodny, wolny od wirusów lub innych szkodliwych kodów, kompletny, legalny lub bezpieczny. Jeśli obowiązujące prawo wymaga jakichkolwiek gwarancji w odniesieniu do serwisu, wszystkie takie gwarancje są ograniczone do dziewięćdziesięciu (90) dni od daty pierwszego użycia.</p>
<p>Niektóre jurysdykcje nie zezwalają na wyłączenie dorozumianych gwarancji, więc powyższe wyłączenie może Cię nie dotyczyć. Niektóre jurysdykcje nie zezwalają na ograniczenia dotyczące długości trwania dorozumianej gwarancji, więc powyższe ograniczenie może Cię nie dotyczyć.</p>`,
            section4: `<h2>Ograniczenie odpowiedzialności</h2>
<p>W maksymalnym zakresie dozwolonym przez prawo, w żadnym wypadku nie będziemy ani nasi dostawcy odpowiedzialni wobec ciebie lub jakiejkolwiek osoby trzeciej za utracone zyski, utratę danych, koszty pozyskania zamiennych produktów lub jakiekolwiek pośrednie, wynikowe, przykładowe, przypadkowe, szczególne lub karne szkody wynikające z lub związane z niniejszymi warunkami lub twoim korzystaniem z serwisu, lub brakiem możliwości korzystania z niego, nawet jeśli zostaliśmy poinformowani o możliwości wystąpienia takich szkód. Dostęp do serwisu i korzystanie z niego odbywa się na własne ryzyko i odpowiedzialność, a także będziesz odpowiedzialny za wszelkie uszkodzenia swojego urządzenia lub systemu komputerowego, lub utratę danych wynikającą z tego działania.</p>

<p>W maksymalnym zakresie dozwolonym przez prawo, niezależnie od wszelkich przeciwnych ustaleń zawartych w niniejszym dokumencie, nasza odpowiedzialność wobec ciebie za wszelkie szkody wynikające z lub związane z niniejszą umową, będzie zawsze ograniczona do maksymalnie pięćdziesięciu dolarów amerykańskich (50 USD). Istnienie więcej niż jednego roszczenia nie zwiększy tego limitu. Zgadzasz się, że nasi dostawcy nie będą mieli żadnej odpowiedzialności wynikającej z lub związanej z niniejszą umową.</p>

<p>Niektóre jurysdykcje nie zezwalają na ograniczenie lub wyłączenie odpowiedzialności za szkody przypadkowe lub wynikowe, więc powyższe ograniczenie lub wyłączenie może cię nie dotyczyć.</p>

<p><strong>Czas trwania i rozwiązanie umowy.</strong> Z zastrzeżeniem niniejszej sekcji, niniejsze warunki pozostaną w pełnej mocy, dopóki korzystasz z serwisu. Możemy zawiesić lub zakończyć twoje prawa do korzystania z serwisu w dowolnym momencie, z dowolnego powodu, według naszego wyłącznego uznania, w tym za jakiekolwiek korzystanie z serwisu niezgodne z tymi warunkami. Po zakończeniu twoich praw wynikających z niniejszych warunków, twoje konto i prawo do dostępu i korzystania z serwisu zostaną natychmiast zakończone. Rozumiesz, że jakiekolwiek zakończenie twojego konta może wiązać się z usunięciem twoich treści użytkownika powiązanych z twoim kontem z naszych aktywnych baz danych. Nie będziemy ponosić żadnej odpowiedzialności wobec ciebie za jakiekolwiek zakończenie twoich praw wynikających z niniejszych warunków. Nawet po zakończeniu twoich praw wynikających z niniejszych warunków, następujące postanowienia niniejszych warunków pozostaną w mocy: Sekcja 2 do 2.5, Sekcja 3 oraz Sekcja 4 do 10.</p>
<p></p>
<p>Odszkodowanie. Będziesz bronić, odszkodowywać i chronić nas, naszych partnerów i personel przed wszelkimi roszczeniami, stratami i kosztami (w tym opłatami prawniczymi) wynikającymi z lub związanymi z korzystaniem przez Ciebie z Usług, w tym Twoimi treściami, produktami lub usługami, które opracowujesz lub oferujesz w związku z Usługami, oraz Twoim naruszeniem niniejszych Warunków lub obowiązującego prawa.</p>
<p>Zrzeczenie się odpowiedzialności. USŁUGI SĄ ŚWIADCZONE „W STANIE, W JAKIM SĄ.” Z WYJĄTKIEM SYTUACJI ZABRONIONYCH PRZEZ PRAWO, MY ORAZ NASI PARTNERZY I LICENCJODAWCY NIE GWARANTUJEMY (WYRAŹNYCH, DOROZUMIANYCH, USTAWOWYCH ANI INNYCH) W ODNIESIENIU DO USŁUG I ZRZEKAMY SIĘ WSZELKICH GWARANCJI, W TYM MIĘDZY INNYMI GWARANCJI PRZYDATNOŚCI HANDLOWEJ, PRZYDATNOŚCI DO OKREŚLONEGO CELU, ZADOWALAJĄCEJ JAKOŚCI, NIENARUSZALNOŚCI I SPOKOJNEGO UŻYTKOWANIA ORAZ JAKICHKOLWIEK GWARANCJI WYNIKAJĄCYCH Z JAKIEJKOLWIEK DROGI POSTĘPOWANIA LUB ZWYCZAJU HANDLOWEGO. NIE GWARANTUJEMY, ŻE USŁUGI BĘDĄ NIEPRZERWANE, DOKŁADNE CZY WOLNE OD BŁĘDÓW LUB ŻE JAKIEKOLWIEK TREŚCI BĘDĄ BEZPIECZNE LUB NIE ZOSTANĄ UTRACONE LUB ZMODYFIKOWANE.</p>`,
            section5: `<h2>Polityka Praw Autorskich.</h2>
<p>Szanujemy własność intelektualną innych i prosimy użytkowników naszej witryny o to samo. W związku z naszą witryną przyjęliśmy i wdrożyliśmy politykę zgodną z prawem autorskim, która przewiduje usunięcie wszelkich naruszających materiały oraz zakończenie korzystania z naszej witryny internetowej przez użytkowników, którzy wielokrotnie naruszają prawa własności intelektualnej, w tym prawa autorskie. Jeśli uważasz, że jeden z naszych użytkowników, korzystając z naszej witryny, dokonuje bezprawnego naruszenia praw autorskich do dzieła i chcesz, aby domniemane materiały naruszające prawo zostały usunięte, należy dostarczyć następujące informacje w formie pisemnego powiadomienia (zgodnie z 17 U.S.C. § 512(c)) naszemu wyznaczonemu Agentowi ds. Praw Autorskich:</p>
<p>- Twój fizyczny lub elektroniczny podpis;</p>
<p>- identyfikacja dzieła (dzieł) chronionych prawem autorskim, które, jak twierdzisz, zostały naruszone;</p>
<p>- identyfikacja materiału w naszych usługach, który, jak twierdzisz, narusza prawa i prosisz nas o jego usunięcie;</p>
<p>- wystarczające informacje, aby umożliwić nam zlokalizowanie takiego materiału;</p>
<p>- Twój adres, numer telefonu i adres e-mail;</p>
<p>- oświadczenie, że masz w dobrej wierze przekonanie, że użycie zakwestionowanego materiału nie jest autoryzowane przez właściciela praw autorskich, jego agenta lub zgodnie z prawem; oraz</p>
<p>- oświadczenie, że informacje w powiadomieniu są dokładne i pod rygorem odpowiedzialności karnej za składanie fałszywych oświadczeń, że jesteś właścicielem praw autorskich, które, jak twierdzisz, zostały naruszone lub że jesteś upoważniony do działania w imieniu właściciela praw autorskich.</p>
<p>Proszę pamiętać, że zgodnie z 17 U.S.C. § 512(f), każde fałszywe przedstawienie faktów w pisemnym powiadomieniu automatycznie podlega odpowiedzialności składającej skargę strony za wszelkie szkody, koszty i opłaty prawne poniesione przez nas w związku z pisemnym powiadomieniem i zarzutem naruszenia praw autorskich.</p>`,
            section6: `<h2>Ogólne</h2>
<p>Niniejsze Warunki mogą być okresowo zmieniane, a jeśli wprowadzimy jakiekolwiek istotne zmiany, możemy powiadomić Cię, wysyłając Ci wiadomość e-mail na ostatni adres e-mail, który nam podałeś i/lub wyraźnie umieszczając informację o zmianach na naszej stronie. Jesteś odpowiedzialny za dostarczenie nam swojego najaktualniejszego adresu e-mail. W przypadku, gdy ostatni podany przez Ciebie adres e-mail nie jest aktualny, wysłanie przez nas wiadomości e-mail zawierającej takie powiadomienie będzie stanowić skuteczne powiadomienie o opisanych zmianach. Wszelkie zmiany niniejszych Warunków będą obowiązywać od pierwszego z następujących terminów: trzydzieści (30) dni kalendarzowych po wysłaniu przez nas powiadomienia e-mail do Ciebie lub trzydzieści (30) dni kalendarzowych po umieszczeniu przez nas powiadomienia o zmianach na naszej stronie. Zmiany te będą obowiązywać natychmiast dla nowych użytkowników naszej strony. Kontynuowanie korzystania z naszej strony po powiadomieniu o takich zmianach oznacza Twoje uznanie takich zmian i zgodę na związanie się warunkami takich zmian. Rozwiązywanie sporów. Proszę uważnie przeczytać niniejszą Umowę Arbitrażową. Jest ona częścią Twojej umowy z nami i wpływa na Twoje prawa. Zawiera procedury dotyczące OBOWIĄZKOWEGO WIĄŻĄCEGO ARBITRAŻU I ZRZECZENIA SIĘ POWÓDZTWA ZBIOROWEGO.</p>
<p><strong>Zastosowanie Umowy Arbitrażowej.</strong> Wszelkie roszczenia i spory w związku z Warunkami lub korzystaniem z jakiegokolwiek produktu lub usługi dostarczanej przez nas, których nie można rozwiązać nieformalnie lub w sądzie ds. drobnych roszczeń, będą rozstrzygane przez wiążący arbitraż na zasadzie indywidualnej zgodnie z warunkami niniejszej Umowy Arbitrażowej. O ile nie uzgodniono inaczej, wszystkie postępowania arbitrażowe będą prowadzone w języku angielskim. Niniejsza Umowa Arbitrażowa ma zastosowanie do Ciebie i do nas, oraz do wszelkich filii, agentów, pracowników, poprzedników prawnych, następców i cesjonariuszy, jak również wszystkich upoważnionych lub nieupoważnionych użytkowników lub beneficjentów usług lub towarów dostarczanych na mocy Warunków.</p>
<p><strong>Wymóg powiadomienia i nieformalne rozwiązywanie sporów.</strong> Zanim którakolwiek ze stron będzie mogła wszcząć postępowanie arbitrażowe, strona musi najpierw wysłać do drugiej strony pisemne Powiadomienie o Sporze opisujące naturę i podstawę roszczenia lub sporu oraz żądane odszkodowanie. Powiadomienie do nas powinno zostać wysłane na nasz kontaktowy adres e-mail. Po otrzymaniu Powiadomienia, Ty i my możemy próbować rozwiązać roszczenie lub spór nieformalnie. Jeśli ty i my nie rozwiążemy roszczenia lub sporu w ciągu trzydziestu (30) dni od otrzymania Powiadomienia, którakolwiek ze stron może rozpocząć postępowanie arbitrażowe. Kwota jakiejkolwiek oferty ugodowej złożonej przez którąkolwiek ze stron nie może zostać ujawniona arbitrowi dopiero po tym, jak arbiter ustali kwotę odszkodowania, do którego którejkolwiek ze stron przysługuje.</p>
<p><strong>Zasady Arbitrażu.</strong> Arbitraż powinien być inicjowany za pośrednictwem American Arbitration Association, uznanego dostawcy alternatywnych metod rozstrzygania sporów, który oferuje arbitraż zgodnie z postanowieniami niniejszej sekcji. Jeżeli AAA nie jest dostępna do prowadzenia arbitrażu, strony powinny uzgodnić wybór innego dostawcy ADR. Zasady dostawcy ADR będą regulować wszystkie aspekty arbitrażu, chyba że takie zasady są sprzeczne z Warunkami. Zasady AAA dotyczące arbitrażu konsumenckiego są dostępne online na adr.org lub pod numerem telefonu 1-800-778-7879. Arbitraż będzie prowadzony przez jednego neutralnego arbitra. Wszelkie roszczenia lub spory, w których łączna kwota odszkodowania wynosi mniej niż dziesięć tysięcy dolarów amerykańskich (10 000,00 USD), mogą być rozstrzygane poprzez wiążący arbitraż bez konieczności stawienia się osobiście, według wyboru strony dochodzącej swoich praw. W przypadku roszczeń lub sporów, gdzie łączna kwota odszkodowania wynosi dziesięć tysięcy dolarów amerykańskich (10 000,00 USD) lub więcej, prawo do przesłuchania zostanie określone zgodnie z Zasadami Arbitrażu. Wszelkie przesłuchania odbędą się w miejscu oddalonym o nie więcej niż 100 mil od Twojego miejsca zamieszkania, chyba że mieszkasz poza Stanami Zjednoczonymi, i chyba że strony uzgodnią inaczej. Jeśli mieszkasz poza USA, arbiter przekaże stronom stosowne powiadomienie o dacie, godzinie i miejscu wszelkich przesłuchań ustnych. Wszelkie orzeczenia arbitra mogą być wpisane do rejestru w każdym sądzie właściwym. Jeżeli arbiter przyzna Ci odszkodowanie większe niż ostatnia oferta ugodowa, jaką złożyliśmy Tobie przed rozpoczęciem arbitrażu, zapłacimy Ci większą z kwot, czyli odszkodowanie lub 2 500,00 USD. Każda ze stron pokrywa własne koszty i wydatki wynikające z arbitrażu i płaci równą część opłat i kosztów dostawcy ADR.</p>
<p><strong>Dodatkowe zasady dla arbitrażu bez konieczności stawienia się osobiście. </strong> Jeśli wybrano arbitraż bez konieczności stawienia się osobiście, arbitraż będzie prowadzony przez telefon, online i/lub opierał się wyłącznie na pisemnych wnioskach; konkretny sposób zostanie wybrany przez stronę inicjującą arbitraż. Arbitraż nie będzie obejmował osobistego stawienia się stron lub świadków, chyba że strony uzgodnią inaczej.</p>
<p><strong>Limity czasowe.</strong> Jeśli Ty lub my dążymy do arbitrażu, działanie arbitrażowe musi zostać rozpoczęte i/lub zażądane w terminie określonym przez przepisy o przedawnieniu oraz w ramach wszelkich terminów narzuconych przez zasady AAA dla odpowiedniego roszczenia.</p>
<p><strong>Uprawnienia arbitra.</strong> Jeśli arbitraż zostanie wszczęty, arbiter zdecyduje o prawach i obowiązkach Twoich oraz naszych, a spór nie zostanie skonsolidowany z innymi sprawami ani połączony z innymi sprawami lub stronami. Arbiter będzie miał uprawnienia do wydawania orzeczeń rozstrzygających w całości lub w części wszelkie roszczenia. Arbiter będzie miał uprawnienia do przyznawania odszkodowania pieniężnego oraz do udzielania wszelkich niematerialnych środków prawnych lub odszkodowania dostępnego dla jednostki zgodnie z obowiązującym prawem, Zasadami AAA oraz Warunkami. Arbiter wyda pisemne orzeczenie i uzasadnienie decyzji opisujące zasadnicze ustalenia i wnioski, na których opiera się orzeczenie. Arbiter ma takie same uprawnienia do przyznawania środków prawnych na zasadzie indywidualnej, jak sędzia w sądzie. Orzeczenie arbitra jest ostateczne i wiążące dla Ciebie oraz nas.</p>
<p><strong>Zrzeczenie się prawa do procesu przed ławą przysięgłych.</strong> STRONY TYM SAMYM ZRZEKAJĄ SIĘ SWOICH KONSTYTUCYJNYCH I USTAWOWYCH PRAW DO SĄDU I PROCESU PRZED SĘDZIĄ LUB ŁAWĄ PRZYSIĘGŁYCH, wybierając zamiast tego, że wszelkie roszczenia i spory będą rozstrzygane przez arbitraż na mocy niniejszej Umowy Arbitrażowej. Procedury arbitrażowe są zazwyczaj bardziej ograniczone, bardziej efektywne i mniej kosztowne niż zasady obowiązujące w sądzie i podlegają bardzo ograniczonej ocenie przez sąd. W przypadku, gdyby między Tobą a nami powstał jakikolwiek spór w sądzie stanowym lub federalnym o uchylenie lub wykonanie wyroku arbitrażowego lub inne, TY I MY ZRZEKAMY SIĘ WSZELKICH PRAW DO PROCESU PRZED ŁAWĄ PRZYSIĘGŁYCH, wybierając zamiast tego, że spór zostanie rozstrzygnięty przez sędziego.</p>
<p><strong>Zrzeczenie się działań zbiorowych lub skonsolidowanych.</strong> Wszelkie roszczenia i spory w ramach niniejszej umowy arbitrażowej muszą być arbitrażowane lub rozstrzygane indywidualnie, a nie na zasadzie pozwu zbiorowego, i roszczenia więcej niż jednego klienta lub użytkownika nie mogą być arbitrażowane lub rozstrzygane wspólnie lub skonsolidowane z roszczeniami jakiegokolwiek innego klienta lub użytkownika.</p>
<p><strong>Poufność.</strong> Wszystkie aspekty postępowania arbitrażowego będą ściśle poufne. Strony zgadzają się zachować poufność, chyba że prawo wymaga inaczej. Niniejszy paragraf nie uniemożliwia żadnej ze stron złożenia w sądzie informacji niezbędnych do egzekwowania niniejszej Umowy, egzekwowania wyroku arbitrażowego ani ubiegania się o środki ochronne lub sprawiedliwe.</p>`,
            section7: `<p><strong>Rozdzielność postanowień.</strong> Jeśli jakakolwiek część lub części niniejszej Umowy Arbitrażowej zostaną uznane za nieważne lub niewykonalne przez sąd właściwej jurysdykcji, wówczas taka część lub części nie będą miały mocy prawnej i zostaną oddzielone, a pozostała część Umowy pozostanie w pełnej mocy.</p>
<p><strong>Prawo do zrzeczenia się.</strong> Każde lub wszystkie prawa i ograniczenia określone w niniejszej Umowie Arbitrażowej mogą zostać zrzeczone przez stronę, wobec której roszczenie jest dochodzone. Takie zrzeczenie się nie będzie miało wpływu na żaden inny fragment niniejszej Umowy Arbitrażowej.</p>
<p><strong>Przetrwanie Umowy.</strong> Niniejsza Umowa Arbitrażowa przetrwa zakończenie Twojej relacji z nami.</p>
<p><strong>Sąd ds. drobnych roszczeń.</strong> Niezależnie od powyższego, Ty lub my możemy wnieść indywidualne powództwo do sądu ds. drobnych roszczeń.</p>
<p><strong>Doraźne środki naprawcze.</strong> Niezależnie od powyższego, każda ze stron może ubiegać się o doraźne środki naprawcze przed sądem stanowym lub federalnym w celu utrzymania status quo do czasu rozstrzygnięcia arbitrażu. Wniosek o środki tymczasowe nie będzie uważany za zrzeczenie się jakichkolwiek innych praw lub obowiązków wynikających z niniejszej Umowy Arbitrażowej.</p>
<p><strong>Roszczenia niepodlegające arbitrażowi.</strong> Niezależnie od powyższego, roszczenia dotyczące zniesławienia, naruszenia ustawy o oszustwach komputerowych i nadużyciach oraz naruszenia lub przywłaszczenia patentów, praw autorskich, znaków towarowych lub tajemnic handlowych drugiej strony nie będą podlegały niniejszej Umowie Arbitrażowej.</p>
<p>W każdych okolicznościach, w których powyższa Umowa Arbitrażowa zezwala stronom na prowadzenie postępowania sądowego, strony tym samym zgadzają się poddać osobistej jurysdykcji sądów zlokalizowanych w hrabstwie Niderlandzkim w Kalifornii, w tych celach.</p>
<p>Strona może podlegać amerykańskim przepisom kontroli eksportu i może podlegać regulacjom eksportowym lub importowym w innych krajach. Zgadzasz się nie eksportować, ponownie eksportować ani przekazywać, bezpośrednio lub pośrednio, żadnych amerykańskich danych technicznych uzyskanych od nas lub jakichkolwiek produktów wykorzystujących takie dane, z naruszeniem przepisów exportowych Stanów Zjednoczonych.</p>`,
            section8: `<p><strong>Komunikacja elektroniczna.</strong> Komunikacja między Tobą a nami odbywa się za pośrednictwem środków elektronicznych, niezależnie od tego, czy korzystasz z Witryny, wysyłasz nam e-maile, czy my publikujemy ogłoszenia na stronie lub komunikujemy się z Tobą za pośrednictwem e-maila. Do celów umownych, (a) wyrażasz zgodę na otrzymywanie komunikacji od nas w formie elektronicznej oraz (b) zgadzasz się, że wszelkie warunki, umowy, powiadomienia, ujawnienia i inne komunikaty, które przekazujemy Ci drogą elektroniczną, spełniają wszelkie prawne obowiązki, jakie takie komunikaty spełniałyby, gdyby były wykonane w formie pisemnej na papierze.</p>
<p><strong>Całość Warunków. </strong>Niniejsze Warunki stanowią całość umowy między Tobą a nami dotyczącej korzystania z Witryny. Nasze zaniechanie wykonania lub egzekwowania jakiegokolwiek prawa lub postanowienia tych Warunków nie będzie stanowić zrzeczenia się takiego prawa lub postanowienia. Tytuły sekcji w tych Warunkach są jedynie dla wygody i nie mają żadnego skutku prawnego lub umownego. Słowo "w tym" oznacza "w tym bez ograniczeń". Jeśli jakiekolwiek postanowienie niniejszych Warunków zostanie uznane za nieważne lub niewykonalne, pozostałe postanowienia niniejszych Warunków pozostaną nienaruszone, a nieważne lub niewykonalne postanowienie zostanie uznane za zmodyfikowane, tak aby było ważne i wykonalne w maksymalnym zakresie dozwolonym przez prawo. Twoja relacja z nami ma charakter niezależnego kontrahenta i żadna ze stron nie jest agentem ani partnerem drugiej. Niniejsze Warunki oraz Twoje prawa i obowiązki w nich zawarte nie mogą zostać przeniesione, podkontraktowane, delegowane ani w inny sposób przekazane przez Ciebie bez naszej uprzedniej pisemnej zgody, a jakiekolwiek próby przeniesienia, podkontraktowania, delegowania lub przekazania z naruszeniem powyższego będą nieważne. Możemy swobodnie przypisywać te Warunki. Warunki określone w niniejszych Warunkach będą wiążące dla przypisanych.</p>
<p><strong>Twoja Prywatność.</strong> Proszę przeczytaj naszą Politykę Prywatności.</p>
<p><strong>Informacje o prawach autorskich/znakach towarowych.</strong> Copyright ©. Wszelkie prawa zastrzeżone. Wszystkie znaki towarowe, logo i znaki usługowe wyświetlane na stronie są naszą własnością lub własnością innych osób trzecich. Nie masz prawa do używania tych znaków bez naszej uprzedniej pisemnej zgody lub zgody strony trzeciej, która może być właścicielem tych znaków.</p>
<h2>Informacje kontaktowe</h2>
<p>Email: watch_support@adflash.cn</p>`
        },

        privacy: {
            section1: ` <p>Ochrona Twojej prywatności jest dla nas ważna. Mamy nadzieję, że poniższe oświadczenie pomoże Ci zrozumieć, jak produkty traktują dane osobowe, które możesz nam okazjonalnie przekazywać za pośrednictwem Internetu.</p>
<p>W oparciu o nasze aplikacje i powiązane usługi, możesz ubiegać się o korzystanie ze specjalnej usługi funkcjonalnej świadczonej przez stronę trzecią. Jeśli musisz zarejestrować się i skorzystać z jakiejkolwiek specjalnej usługi funkcjonalnej, prosimy o uważne przeczytanie warunków świadczenia takiej usługi funkcjonalnej. Akceptując lub korzystając z jakiejkolwiek specjalnej usługi funkcjonalnej, potwierdzasz, że przeczytałeś i zaakceptowałeś niniejszą Umowę oraz warunki świadczenia takiej specjalnej usługi funkcjonalnej i zgadzasz się nimi związanym.</p>
<p>Czasami potrzebujemy informacji, aby dostarczyć usługi, o które prosisz; niniejsze oświadczenie o ochronie prywatności ma zastosowanie do naszych produktów.</p>
<h2>Informacje zbierane i otrzymywane przez Zespół.</h2>
<p>Kiedy korzystasz z Oprogramowania i Usług, możemy automatycznie zbierać i przechowywać następujące rodzaje informacji:</p>
<p>1. Informacje techniczne. Pewne dane techniczne dotyczące Twojego urządzenia, a także Twojego korzystania z Oprogramowania i Usług oraz innego oprogramowania i aplikacji innych firm zainstalowanych na Twoim urządzeniu ("Inne Oprogramowanie"), w tym typu, nazwy i wersji systemu operacyjnego i Innego Oprogramowania, w tym między innymi ich interakcji z Oprogramowaniem i Usługami, ISP (Internet Service Provider), lokalizacji (GPS), adresu protokołu internetowego (IP), anonimowych identyfikatorów użytkownika, konfiguracji urządzenia i identyfikatorów (IDFA - Identyfikator dla Reklamodawców używany w iOS, Advertising ID - Identyfikator dla Reklamodawców używany w Android) i tym podobnych, wersji Oprogramowania i Usług oraz Twojej konfiguracji i ustawień Oprogramowania i Usług;</p>
<p>2. Informacje o użytkowaniu. Pewne informacje dotyczące użytkowania związane z Twoim korzystaniem z urządzenia i interakcjami z nim, w tym dotyczące Oprogramowania i Usług oraz Innego Oprogramowania, np. kiedy i jak korzystasz z Oprogramowania i Usług oraz Innego Oprogramowania. Na przykład, aplikacje, które masz, aplikacje, których używasz oraz jak często z nich korzystasz. Reklamy, które oglądasz, używasz i uzyskujesz do nich dostęp, jak je używasz i jak na nie reagujesz;</p>
<p>3. Sieci społecznościowe. Twoje interakcje w sieciach społecznościowych za pośrednictwem Oprogramowania i Usług;</p>`,
            section2: ` <p>4. Informacje związane z rejestracją. Informacje, które podajesz podczas rejestracji do Oprogramowania i Usług (jeśli dotyczy), takie jak imię i nazwisko, adresy domowe lub służbowe, adres e-mail, numery telefonów i faksów, data urodzenia i płeć;</p>
<p>5. Informacje związane ze wsparciem. Jeśli kontaktujesz się z nami za pośrednictwem sekcji wsparcia lub e-maila, możemy również zbierać Twój adres e-mail, imię i nazwisko oraz wszelkie informacje dobrowolnie przekazane przez Ciebie w związku ze wsparciem. Kiedy korzystasz z Oprogramowania i Usług, automatycznie generujemy anonimowy wewnętrzny identyfikator użytkownika, który jest przypisany do Twojego korzystania z Oprogramowania i Usług ("ID Użytkownika") i może być połączony z dowolnymi z powyższych informacji.</p>
<p>6. Informacje o urządzeniu</p>
<p>Zbieramy informacje o Twoim urządzeniu, w tym model sprzętu, system operacyjny i wersję, unikalne identyfikatory urządzenia (w tym SSAID, GAID), typ przeglądarki i język, numer telefonu komórkowego i informacje o sieci komórkowej, Międzynarodowy Identyfikator Sprzętu Mobilnego („IMEI”), Identyfikator Reklamowy („IDFA”), Identyfikator dla Dostawcy („IDFV”), Zintegrowany Identyfikator Karty Krzemowej („ICCID”), Adres Kontroli Dostępu do Mediów („MAC”), model i rozdzielczość, które będą używane przez nas do obliczania liczby urządzeń korzystających z naszych produktów i naszych Usług oraz analizowania danych dotyczących modeli urządzeń i adaptacji graficznej. Możesz zdecydować się nie podawać pewnych informacji, ale wtedy możesz nie być w stanie skorzystać z wielu naszych Aplikacji. Zbieramy również pewne informacje o urządzeniu, które pomogą nam zdiagnozować problemy w (miejmy nadzieję rzadkim) przypadku wystąpienia awarii lub innego problemu podczas korzystania z naszych Usług.</p>
<p>7. Aparat i zdjęcia</p>
<p>Wiele z naszych Usług wymaga zbierania obrazów i innych informacji z aparatu i zdjęć na Twoim urządzeniu. Będziemy mieli dostęp do Twojego aparatu i zdjęć tylko po wyrażeniu przez Ciebie zgody. Możesz zdecydować się nie podawać tych informacji, ale wtedy możesz nie być w stanie skorzystać z wielu naszych Usług.</p>
<p>8. Lokalne przechowywanie</p>
<p>Możemy zbierać i przechowywać informacje lokalnie na Twoim urządzeniu za pomocą mechanizmów takich jak przeglądarkowa pamięć lokalna (w tym HTML 5) i pamięci podręczne danych aplikacji. Informacje przechowywane przez nas nie będą udostępniane jakiejkolwiek stronie trzeciej.</p>
<p>9. INFORMACJE NIEOSOBISTE</p>`,
            section3: ` <p> Informacje nieosobiste to dane, które nie pozwalają na bezpośrednie powiązanie z konkretną osobą, takie jak Twój identyfikator Android, model CPN, rozmiar pamięci, numer IMEI telefonu, model telefonu, rom, nazwa zainstalowanej aplikacji i nazwa pakietu, operator telefonii, lokalizacja, instalacja, odinstalowanie, częstotliwość użytkowania, itp.</p>
<p> Możemy zbierać i wykorzystywać informacje nieosobiste w następujących okolicznościach. Aby lepiej zrozumieć zachowania użytkowników, rozwiązywać problemy związane z produktami i usługami, ulepszać nasze produkty, usługi i reklamy, możemy zbierać informacje nieosobiste, takie jak nazwa zainstalowanej aplikacji i nazwa pakietu, dane instalacyjne, częstotliwość użytkowania, kraj, sprzęt i kanał.</p>
<h2>Jak Zespół Wykorzystuje Twoje Informacje?</h2>
<p>Zespół może wykorzystywać zebrane informacje do celów analitycznych, biznesowych i operacyjnych w następujący sposób:</p>
<p>1. do działania i aktualizacji Oprogramowania i Usług oraz zapewnienia Ci istniejącej i nowej funkcjonalności i funkcji;</p>
<p>2. aby ulepszać i dostosowywać Oprogramowanie i Usługi;</p>
<p>3. aby dostarczać Ci spersonalizowane treści, w tym poprzez dostarczanie ukierunkowanych reklam zainteresowań od nas lub naszych partnerów;</p>
<p>4. aby utrzymywać i poprawiać jakość i działanie Oprogramowania i Usług;</p>
<p>5. aby powiadamiać Cię o aktualizacjach Oprogramowania i Usług;</p>
<p>6. aby zapewniać Ci wsparcie i rozwiązywać Twoje skargi i/lub uwagi;</p>
<p>7. aby podjąć wszelkie działania w przypadku sporu dotyczącego Ciebie w związku z Oprogramowaniem i Usługami;</p>`,
            section4: ` <p>8. aby egzekwować warunki i zasady mające zastosowanie do Oprogramowania i Usług oraz aby zapobiegać nielegalnym działaniom i ich niewłaściwemu używaniu;</p>
<p>9. aby spełniać i zaspokajać wszelkie obowiązujące przepisy prawa, procedury prawne lub egzekwowalne wnioski rządowe. Udostępnianie Informacji Stronom Trzecim.</p>
<h2>Prywatność Dzieci</h2>
<p>Te Usługi nie są przeznaczone dla osób poniżej 13 roku życia. Nie zbieram świadomie danych osobowych dzieci poniżej 13 roku życia. W przypadku, gdy odkryję, że dziecko poniżej 13 roku życia dostarczyło mi swoje dane osobowe, natychmiast usuwam je z naszych serwerów. Jeśli jesteś rodzicem lub opiekunem i zdajesz sobie sprawę, że Twoje dziecko przekazało nam swoje dane osobowe, prosimy o kontakt, abym mógł podjąć niezbędne działania.</p>
<h2>Zmiany w Polityce Prywatności</h2>
<p>Mogę od czasu do czasu aktualizować naszą Politykę Prywatności. Zalecamy więc okresowe przeglądanie tej strony w celu zapoznania się z ewentualnymi zmianami. Powiadomię Cię o wszelkich zmianach, publikując nową Politykę Prywatności na tej stronie. Zmiany te są skuteczne natychmiast po ich opublikowaniu na tej stronie.</p>
<h2>Kontakt</h2>
<p> Jeśli masz jakiekolwiek pytania lub sugestie dotyczące mojej Polityki Prywatności, nie wahaj się skontaktować ze mną pod adresem e-mail: watch_support@adflash.cn</p>`,
        },
        refund: {
            section1: `<p>Dziękujemy za dokonanie zakupu. Jeśli nie jesteś całkowicie zadowolony z zakupu, chcielibyśmy Ci pomóc.</p>
<p>Aby rozpocząć proces zwrotu, postępuj zgodnie z poniższymi krokami.</p>
<p>Produkty subskrypcyjne (np. Subskrypcja Basic)</p>
<p>Jeśli przestaniesz korzystać z subskrypcji, ale zapomnisz ją anulować, zazwyczaj chętnie zwrócimy Ci ostatnią płatność subskrypcyjną, o ile nie korzystałeś z żadnych sesji ani nie tworzyłeś żadnych obrazów po dokonaniu płatności.</p>
<p>Aby zażądać zwrotu, prosimy o wysłanie wniosku o zwrot poprzez swoje konto oraz za pośrednictwem formularza opinii. Możemy potrzebować poprosić Cię o konkretne informacje, aby pomóc nam potwierdzić Twoją tożsamość.</p>
<p>Produkty cyfrowe (np. Pakiety czasowe, subskrypcje)</p>
<p>Nie będą udzielane żadne zwroty, jeśli jakiekolwiek pakiety czasowe i subskrypcje zostały wykorzystane. Nie oferujemy częściowych lub proporcjonalnych zwrotów.</p>
<p>Masz 14 dni kalendarzowych od daty transakcji na zgłoszenie wniosku o zwrot.</p>
<p>Po otrzymaniu Twojego wniosku o zwrot, nasz personel sprawdzi status Twojego członkostwa, aby ustalić, czy jesteś uprawniony do zwrotu.</p>`,
            section2: `<p>Jeżeli zostanie zatwierdzony, Twój zwrot zostanie przetworzony. Zwrot zostanie przelany na oryginalną [metodę płatności] używaną przy zakupie.</p>
<p>Opóźnione lub brakujące zwroty</p>
<p>Jeśli nie otrzymałeś jeszcze zwrotu, najpierw sprawdź ponownie swoje konto bankowe.</p>
<p>Następnie skontaktuj się z firmą obsługującą Twoją kartę bankową, może potrwać trochę czasu, zanim Twój zwrot zostanie oficjalnie zaksięgowany.</p>
<p>Następnie skontaktuj się ze swoim bankiem. Zazwyczaj przetworzenie zwrotu zajmuje pewien czas.</p>
<p>Jeśli zrobiłeś to wszystko, a nadal nie otrzymałeś zwrotu, prosimy o kontakt pod adresem watch_support@adflash.cn</p>
<p>Przedmioty na wyprzedaży</p>
<p>Zwracane mogą być tylko przedmioty w regularnych cenach, niestety przedmioty na wyprzedaży nie mogą być zwracane.</p>`,
        },
        us: {
            section1: `<p>Informacje o Firmie</p>
<p>Nazwa: HONG KONG ADFLASH MEDIA LIMITED</p>
<p>Adres: RM 1101 11/F SAN TOI BUILDING NO.139 CONNAUGHT ROAD CENTRAL HK</p>
<p>Email: watch_support@adflash.cn</p>`,
        },
    }
}