module.exports = {
    language: "土耳其语",


    setting: {
        index: {
            title: "Ayarlar",
            problem: "Sorun Geri Bildirimi",
            order: "Sipariş Kaydı",
            notice: "Bildirim",
            language: "Dil",
            clear: "Önbelleği Temizle",
            clearTip: "Önbelleği temizlemeyi onaylamak istiyor musunuz?",
            confirm: "Onayla",
            cancel: "İptal",
        },
        language: {
            title: "dil",
            langList: ["Basitleştirilmiş Çince", "Geleneksel çince", "İngilizce", "Arapça", "Lehçe", "Farsça", "Danimarkalı", "Almanca", "Rusça", "Fransızca", "Flemenkçe", "Çek", "Romen", "Portekizce", "Japonca", "Tay dili", "Türkçe", "İspanyol", "Yunan", "İtalyan", "Vietnam", "Hintçe"],
        },
        order: {
            title: "Sipariş Kaydı",
            success: "Başarıyla Alındı",
            unpaid: "Ödenmedi",
            paid: "Ödendi",
            expired: "Süresi Dolmuş",
            refunded: "İade Edildi",
            pTime: "Ödeme Zamanı",
            eTime: "Son Kullanma Zamanı",
            pMethod: "Ödeme Yöntemi",
            pObject: "Ödeme Nesnesi",
            oNumber: "Sipariş Numarası",
            pType: "Paket Türü",
            oTime: "Alma Zamanı",
            watch: "IMEI'yi İzle",
            delete: "Siparişi Sil",
            refund: "İade Talebi",
            empty: "Henüz kayıt yok",
            grade: "Paket seviyesi",
            package: "Paket durumu",
            use: "Kullanım durumu",
            remaining: "Paket bakiyesi",
            basic: "Normal",
            pro: "Gelişmiş",
            refundAmount: "İade tutarı"
        },
        problem: {
            title: "Sorun Geri Bildirimi",
            feedback: "Geri Bildirim Türü",
            please: "Lütfen Seçin",
            content: "İçerik",
            improve: "Fonksiyonel gereksinimler, ürün şikayetleri vb. gibi önerilerinizi yazınız. Geliştirmek için çok çalışacağız",
            upload: "Resim Yükle",
            submit: "Gönder",
            general: "Genel Geri Bildirim",
            purchase: "Satın Alma ile İlgili",
            bug: "Hata Raporu",
            suggestion: "Öneri",
            other: "Diğer",
        },
        notice: {
            title: "Bildirim",
            type: "Bildirim türü: Sistem yanıtı",
            time: "Bildirim Zamanı: "
        }
    },
    subscription: {
        jarvis: {
            funcList: ["Yapay Zeka Soru-Cevap", "Yapay Zeka Çizim", "Temel Akıllı AI Versiyonu", "Gelişmiş Akıllı AI", "Çok Modlu Giriş ve Çıkış", "Daha Ayrıntılı Soru-Cevap", "Daha Akıllı Modeller", "Daha Hızlı Yanıt"],
            times: "Keza",
            days: "Gün",
            more: "Daha Gelişmiş AI",
            custom: "Özel Akıllı AI",
            delay: "Hesabınıza kredi yüklenmesinde küçük bir gecikme olabilir. Lütfen bekleyin ve saat uygulamasından çıkıp yeniden girin.",
            member: "Üye Karşılaştırması",
            packageName: "Paket Adı",
            unlimited: "sınırsız",
            month: "Ay",
            },
        index: {
            nav: {
                l: "Pro",
                r: "Zaman",
                language: "Dil",
                notice: "Bildirim",
                feedback: "Geri Bildirim"
            },
            proBox: {
                expire: {
                    title: "Son Kullanma Tarihi",
                    date: "Henüz Abone Değil",
                },
                packTip: "Çeşitli aylık paketlerimiz var",
                packList: ["Üst Düzey Abonelik", "Temel Abonelik", "Normal Abonelik"],
                benefit: {
                    origin: "Orijinal fiyat: ",
                    remaining: "Kalan",
                    title: "Abonelik Avantajları",
                    value: "değer",
                    benefitList: [
                        { labelTime: "Sınırsız Kullanım", labelSave: "Resim Kaydetme", labelPer: "Tam Yetkiler" },
                        { labelTime: "300 Kullanım", labelSave: "Kayıt Depolama", labelPer: "Temel Yetkiler" },
                        { labelTime: "100 Kullanım", labelSave: "Kayıt Depolama", labelPer: "Düşük Yetkiler" },
                    ],
                    desc: "$3.99/Ay 100 ücretsiz kullanım içerir, $9.99/Ay 300 ücretsiz kullanım içerir.",
                    tip: "Hesabınıza kredi yüklenmesinde hafif bir gecikme olacaktır. Lütfen biraz bekleyin ve saat uygulamasından çıkıp yeniden giriş yapın."
                },
            },
            timeBox: {
                packTip: "Çeşitli aylık paketlerimiz var",
                packList: ["Yeni kullanıcı indirimli Zaman paketi", "50 Zaman paketi", "100 Zaman paketi", "300 Zaman paketi"],
                times: {
                    title: "Zamanların Rolü",
                    timesList: ["Çizim için kullanılabilir", "GPT Soru-Cevap için kullanılabilir", "Burç kehaneti için kullanılır", "Öğrenme için kullanılabilir", "Nesneleri tanımak için kullanılır"],
                    desc: "50 kullanım $2.99, 100 kullanım $4.99, 300 kullanım $12.99. $0.99 50 kullanımlık paket yeni kullanıcılar içindir ve sadece bir kez satın alınabilir (24 saat içinde geçerli)",
                }
            },
            staticBox: {
                confirm: "Onayla",
                privacy: "Gizlilik",
                terms: "Şartlar",
                refund: "İade",
                cancel: "İstediğiniz zaman iptal edin",
                funcTitle: "PRO deneyimi ile yaratıcılığınızı serbest bırakın",
                function: "Fonksiyon",
                free: "Ücretsiz",
                pro: "Pro",
                funcList: ["Zaman bir gün için geçerlidir", "10 Zaman Çizim/Sohbet (Üç kez etkinleştirebilme)", "Bir kez abone olduğunuzda 30 gün", "Daha fazla kullanım hakkı", "Resimler sonsuza kadar saklanır", "Satın alınabilecek kullanım sayısı"],
                details: "Detaylar",
            },
            dialog: {
                error: "Ödeme yapılamıyor. Lütfen saatinizdeki QR kodunu tarayın.",
                unbind: "Saat bir ödeme yöntemine bağlanmış. Bağlantıyı kaldırmak istiyor musunuz?",
                beforePay: "Cari hesabı kullanarak ödemeye devam edilip edilmeyeceği",
                renewal: "Otomatik yenilemenin etkinleştirilip etkinleştirilmeyeceği",
                confirm: "Onayla",
                cancel: "İptal",
                change: "değiştir/bağla",
                beforeConsent: "Bu ürüne abone oldunuz",
            }
        },
        NewIndex:{
            l:"Temel",
            r:"Pro",
            proBox:{
                benefit:{
                    title:"Paket Dahil",
                    benefitList:[
                        {labelTime: "Zaman Al",labelSave: "normal Zaman"},
                        {labelTime: "Zaman Al",labelSave: "Pro Zaman"},
                        {labelTime: "Fonksiyon aç",labelSave: "Tüm normal özellikleri aç"},
                        {labelTime: "Fonksiyon aç",labelSave: "Tüm premium özelliklerin kilidini açın"},
                    ]
                }
            },
            funcList: ["Soru-Cevap", "AI çizimi", "Temel sürüm Akıllı AI", "Gelişmiş Akıllı AI", "Metinden-ses", "Daha ayrıntılı yanıtlar", "Daha hızlı yanıtlar"],
            confirm:'Şimdi yükselt',
            dialog:{
                beforePay: "Alışılmış üyeliğe abone oldunuz. Premium üyeliği satın almaya devam ederseniz, alışılmış üyeliğin gerçek kullanımına göre size geri ödeme yapılacaktır. Devam etmek istiyor musunuz?",
            }
        },
        unsubscribe: {
            title: "Sürekli Abonelik",
            subTitle: "Abone Olma",
            unsubscribe: "Aboneliği İptal Et",  
            subscribeTime: "Abonelik süresi",
        },
        refund: {
            title: "İstek iadesi",
            number: "Sipariş numarası",
            content: "Lütfen geri ödemenin nedenini açıklayın",
            empty: "Gönderilemedi, gerekli alanlar boş.",
        },
        order: {
            number: "Sipariş numarası",
            name: "Sipariş adı",
            times: "Sipariş saatleri",
            validity: "Geçerlilik",
            buy: "Hemen satın al",
            days: "Gün sayısı",
            unlock: "Tüm akıllı AI'ların kilidini aç",
            text: "Metinden konuşmaya",
            tips: "Bu bir abonelik ürünüdür. Satın alma işleminden sonra abonelik varsayılan olarak başlar.",
        },
        zhzn: {
            title: "Üyelik ayrıcalıkları",
            t1: "Sınırsız Soru-Cevap",
            d1: "Üyelik süresince Soru-Cevap sohbet işlevini sınırsız kullanma",
            t2: "AI çizimi",
            d2: "AI çizim izinlerinin kilidini aç, metin çizimi işlevini gerçekleştirebilir",
            t3: "Kadran ayarları",
            d3: "Kadran AI çizim sonuçlarına göre ayarlanabilir",
        }
    },
    payment: {
        index: {
            contact: "İletişim bilgileri",
            please: "Lütfen e-posta adresinizi girin",
            email: "Ödeme makbuzu bu E-posta adresine gönderilecektir",
            name: "Kart Üzerindeki İsim",
            firstName: "Ad",
            lastName: "Soyad",
            confidence: "Bilgilerinizin hiçbirini saklamıyoruz, lütfen güvenle girin",
            Card: "Kart Bilgileri",
            confirm: "Aboneliğinizi onaylayarak, Jarvis'ın gelecekteki ödemeler için kartınızı şartlarına uygun olarak borçlandırmasına izin verirsiniz. Aboneliğinizi istediğiniz zaman iptal edebilirsiniz.",
            powered: "Stripe tarafından desteklenmektedir",
            terms: "Şartlar",
            privacy: "Gizlilik",
        }
    },
    policy: {
        title: {
            terms: "Şartlar",
            about: "Hakkında",
            service: "Kullanım Şartları",
            privacy: "Gizlilik Politikası",
            refund: "İade Politikası",
            us: "Hakkımızda",
        },
        service: {
            section1: `<p>https://aiwatchstore.org/ adresinde bulunan web sitesi, HONG KONG ADFLASH MEDIA LIMITED'e ait telif hakkıyla korunan bir eserdir. Sitenin belirli özellikleri, bu özelliklerle ilgili olarak Sitede yayınlanacak ek yönergeler, şartlar veya kurallara tabi olabilir.</p>
<p>Tüm bu ek şartlar, yönergeler ve kurallar, bu Şartlara referans yoluyla dahil edilmiştir.</p>
<p>Bu Kullanım Şartları, Sitenin kullanımınızı yöneten yasal olarak bağlayıcı şartları ve koşulları tanımlar. SİTEYE GİRİŞ YAPARAK BU ŞARTLARA UYGUN OLDUĞUNUZU VE BU ŞARTLARA GİRME YETKİSİ VE KAPASİTESİNE SAHİP OLDUĞUNUZU TEMSİL EDERSİNİZ. SİTEYE ERİŞİM SAĞLAMAK İÇİN EN AZ 18 YAŞINDA OLMALISINIZ. BU ŞARTLARIN HERHANGİ BİR HÜKMÜYLE AYNI FİKİRDE DEĞİLSENİZ, SİTEYE GİRİŞ YAPMAYIN VE/VEYA KULLANMAYIN.</p>
<p>Bu şartlar, anlaşmazlıkları çözmek için bireysel olarak Tahkim Bölümü 10.2'nin kullanılmasını gerektirir ve ayrıca anlaşmazlık durumunda size sunulan çözümleri sınırlar.</p>
<h2>Siteye Erişim</h2>
<p><strong>Bu Şartlara tabi olarak.</strong> Sadece kişisel, ticari olmayan kullanımınız için Siteye erişim sağlamak üzere devredilemez, münhasır olmayan, iptal edilebilir, sınırlı bir lisans veriyoruz.</p>
<p>Bildirimde bulunarak veya bulunmayarak, Siteyi değiştirme, askıya alma veya durdurma hakkını saklı tutarız. Sitenin veya herhangi bir bölümünün değiştirilmesi, kesintiye uğraması veya sonlandırılmasından dolayı size veya üçüncü bir tarafa karşı sorumlu olmayacağımızı onaylıyorsunuz.</p>
<p><strong>Destek veya Bakım Yok.</strong> Site ile ilgili olarak size herhangi bir destek sağlama yükümlülüğümüz olmayacağını kabul ediyorsunuz.</p>
<p>Sağlayabileceğiniz herhangi bir Kullanıcı İçeriği hariç olmak üzere, Sitedeki ve içeriğindeki tüm fikri mülkiyet haklarının, telif hakları, patentler, ticari markalar ve ticari sırlar dahil olmak üzere, tarafımıza veya tedarikçilerimize ait olduğunu biliyorsunuz. Bu Şartlar ve Siteye erişim size herhangi bir fikri mülkiyet hakkı, unvanı veya ilgisi vermez; sadece Bölüm 2.1'de ifade edilen sınırlı erişim hakları dışında. Biz ve tedarikçilerimiz, bu Şartlarda açıkça verilmemiş tüm hakları saklı tutar.</p>`,
            section2: `<h2>Abonelik Şartları</h2>
<p>Abonelik hizmetimize hoş geldiniz (bundan sonra "Hizmet" olarak anılacaktır). Bu hizmet, HONG KONG ADFLASH MEDIA LIMITED (bundan sonra "biz" veya "Şirket" olarak anılacaktır) tarafından sağlanmaktadır. Hizmetimizi kullanarak veya abone olarak, bu Abonelik Şartlarına (bundan sonra "Şartlar" olarak anılacaktır) uymayı kabul edersiniz.</p>
<p>Size abonelik bazlı hizmetler sunacağız, bunlar arasında özel kullanıcı kotaları da bulunmaktadır. Belirli hizmet teklifleri ve ücretler herhangi bir zamanda değişebilir ve e-posta veya web sitemiz üzerinden yapılan bildirimler aracılığıyla güncellenecektir.</p>
<p>Abonelik ücretleri, ödeme yöntemleri ve ödeme döngüleri fiyatlandırma sayfasında detaylandırılmıştır. Gecikmiş ödemeleri olan hesapların ücretli hizmetleri geçici olarak askıya alınacaktır.</p>
<p>Tüm abonelik ücretleri yasa gereği veya tamamen bizim takdirimize bağlı olmadıkça iade edilmez.</p>
<p>Aboneliğinizi istediğiniz zaman iptal edebilirsiniz. İptal durumunda, mevcut abonelik dönemi sonuna kadar hizmet sağlamaya devam edeceğiz, ancak zaten ödenmiş olan ücretler için geri ödeme yapılmayacaktır.</p>
<p>Ayrıca hizmetinizi tek taraflı olarak sonlandırma veya askıya alma hakkımızı şu durumlarda saklı tutarız:</p>
<p>- Bu Şartların ihlali</p>
<p>- Abonelik ücretlerinin ödenmemesi</p>
<p>Yüksek kaliteli hizmetler sunmak için elimizden geleni yapacağız, ancak hizmetin kullanılabilirliği, doğruluğu veya eksiksizliği konusunda açık veya zımni garantilerde bulunmuyoruz.</p>`,
            section3: `<h2>Diğer Kullanıcılar</h2>
<p><strong>Diğer Kullanıcılar.</strong> Her Site kullanıcısı, kendi Kullanıcı İçeriğinden tamamen sorumludur. Kullanıcı İçeriğini kontrol etmediğimiz için, sizin veya başkalarının sağladığı Kullanıcı İçeriğinden sorumlu olmadığımızı kabul ve beyan edersiniz. Böyle bir etkileşim sonucunda oluşan herhangi bir kayıp veya hasardan sorumlu olmayacağımızı kabul edersiniz. Site kullanıcısı ile aranızda bir anlaşmazlık olması durumunda, müdahale etme yükümlülüğümüz bulunmamaktadır.</p>
<p>Buradan, geçmiş, şimdiki ve gelecekteki her türlü anlaşmazlık, talep, ihtilaf, hak, yükümlülük, sorumluluk, eylem ve her türlü doğrudan veya dolaylı olarak Site ile ilgili veya Site'den çıkan taleplerden bizi ve memurlarımızı, çalışanlarımızı, ajanlarımızı, haleflerimizi ve vekillerimizi serbest bırakıyor ve sonsuza dek feragat ediyorsunuz. Kaliforniya sakini iseniz, işbu belge ile Kaliforniya medeni kanun maddesi 1542'yi feragat ediyorsunuz; bu madde şu şekilde beyan eder: "genel bir feragat, alacaklının lehine var olduğunu bilmediği veya şüphelenmediği iddiaları kapsamaz, bu iddialar bilinmiş olsaydı, alacaklının borçlu ile olan çözümünü maddi olarak etkilemesi gerekirdi."</p>
<p><strong>Çerezler ve Web İşaretçileri.</strong> Diğer web siteleri gibi biz de 'çerezler' kullanırız. Bu çerezler, ziyaretçilerin tercihleri ve ziyaret ettikleri web sitesi sayfaları gibi bilgileri depolamak için kullanılır. Bilgiler, ziyaretçilerin tarayıcı türüne ve/veya diğer bilgilere dayalı olarak web sayfamızın içeriğini özelleştirerek kullanıcı deneyimini optimize etmek için kullanılır.</p>
<h2>Feragatnameler</h2>
<p>Site, "olduğu gibi" ve "mevcut olduğu gibi" bir temelde sağlanmaktadır ve biz ve tedarikçilerimiz, ticarete uygunluk, belirli bir amaca uygunluk, unvan, sessiz kullanım, doğruluk veya ihlal etmeme gibi açık, zımni veya yasal her türlü garanti ve koşulların açıkça reddedildiğini beyan ederiz. Biz ve tedarikçilerimiz, sitenin gereksinimlerinizi karşılayacağını, kesintisiz, zamanında, güvenli veya hatasız bir şekilde erişilebilir olacağını veya doğru, güvenilir, virüs veya diğer zararlı kodlardan arındırılmış, eksiksiz, yasal veya güvenli olacağını garanti etmemekteyiz. Geçerli yasa, siteyle ilgili herhangi bir garanti gerektiriyorsa, bu tür tüm garantiler ilk kullanımdan itibaren doksan (90) günle sınırlıdır.</p>
<p>Bazı yargı bölgeleri, zımni garantilerin dışlanmasına izin vermez, bu nedenle yukarıdaki dışlama sizin için geçerli olmayabilir. Bazı yargı bölgeleri, zımni garantinin ne kadar sürebileceğine ilişkin sınırlamalara izin vermez, bu nedenle yukarıdaki sınırlama sizin için geçerli olmayabilir.</p>`,
            section4: `<h2>Sorumluluğun Sınırlandırılması</h2>
<p>Yasa tarafından izin verilen azami ölçüde, biz veya tedarikçilerimiz hiçbir durumda bu şartlardan veya sitenin kullanımınızdan veya kullanamamanızdan kaynaklanan veya bunlarla ilgili olarak size veya herhangi bir üçüncü tarafa karşı herhangi bir kayıp kar, kayıp veri, yedek ürün tedarik maliyetleri veya herhangi bir dolaylı, sonuçsal, örnek teşkil eden, tesadüfi, özel veya cezai zararlardan sorumlu olmayacağız, bu tür zararların olasılığı konusunda bilgilendirildiğimiz durumlarda bile. Siteye erişim ve kullanımı tamamen kendi takdirinize ve riskinize bağlıdır ve cihazınıza veya bilgisayar sisteminize verilecek herhangi bir zarar veya bunun sonucunda oluşacak veri kaybından yalnızca siz sorumlu olacaksınız.</p>
<p>Yasa tarafından izin verilen azami ölçüde, burada yer alan herhangi bir şeye bakılmaksızın, bu sözleşmeden kaynaklanan veya bununla ilgili olarak ortaya çıkan herhangi bir zarar için size olan sorumluluğumuz her zaman azami elli Amerikan doları (50 ABD doları) ile sınırlı olacaktır. Birden fazla talebin varlığı bu sınırı artırmayacaktır. Tedarikçilerimizin bu sözleşmeden kaynaklanan veya bununla ilgili herhangi bir sorumluluğu olmayacağını kabul edersiniz.</p>
<p>Bazı yargı bölgeleri, tesadüfi veya sonuçsal zararlar için sorumluluğun sınırlandırılmasına veya hariç tutulmasına izin vermez, bu nedenle yukarıdaki sınırlandırma veya hariç tutma sizin için geçerli olmayabilir.</p>
<p><strong>Süre ve Fesih.</strong> Bu Bölüme tabi olarak, bu Şartlar, Siteyi kullandığınız sürece tam olarak yürürlükte kalacaktır. Bu Şartların ihlali dahil olmak üzere, tamamen kendi takdirimize bağlı olarak, Siteyi kullanma haklarınızı herhangi bir zamanda askıya alabilir veya sonlandırabiliriz. Bu Şartlara göre haklarınızın sona erdirilmesi üzerine, Hesabınız ve Siteye erişim ve kullanma hakkınız derhal sona erecektir. Hesabınızın herhangi bir şekilde sonlandırılmasının, Hesabınızla ilişkili Kullanıcı İçeriğinin canlı veritabanlarımızdan silinmesini içerebileceğini anlıyorsunuz. Bu Şartlara göre haklarınızın sona erdirilmesiyle ilgili olarak size karşı herhangi bir sorumluluğumuz olmayacaktır. Bu Şartlara göre haklarınız sona erdikten sonra bile, bu Şartların 2. Bölümden 2.5'e, 3. Bölüme ve 4'ten 10'a kadar olan Bölümleri yürürlükte kalacaktır.</p>
<p></p>
<p>Tazminat. Bizi, bağlı kuruluşlarımızı ve personelimizi, Hizmetleri kullanımınızdan kaynaklanan veya bunlarla ilgili olarak ortaya çıkan (Hizmetler ile bağlantılı olarak geliştirdiğiniz veya teklif ettiğiniz içeriğiniz, ürünleriniz veya hizmetleriniz dahil olmak üzere) ve bu Şartların ihlalinden veya geçerli yasaların ihlalinden kaynaklanan taleplere, kayıplara ve masraflara (avukatlık ücretleri dahil) karşı savunacak, tazmin edecek ve zarar görmemesini sağlayacaksınız.</p>
<p>Feragat. HİZMETLER “OLDUĞU GİBİ” SAĞLANMAKTADIR. YASA TARAFINDAN YASAKLANAN DURUMLAR HARİÇ, BİZ VE BAĞLI KURULUŞLARIMIZ VE LİSANS VERENLERİMİZ HİZMETLERLE İLGİLİ (AÇIK, ZIMNİ, YASAL VEYA BAŞKA TÜRLÜ) HER TÜRLÜ GARANTİYİ REDDETMEKTEYİZ VE TİCARETE UYGUNLUK, BELİRLİ BİR AMACA UYGUNLUK, TATMİN EDİCİ KALİTE, İHLAL ETMEME VE RAHAT KULLANIM DAHİL OLMAK ÜZERE HER TÜRLÜ GARANTİYİ REDDEDİYORUZ VE HİZMETLERİN KESİNTİSİZ, DOĞRU VEYA HATASIZ OLACAĞINI VEYA HERHANGİ BİR İÇERİĞİN GÜVENLİ OLUP KAYBOLMAYACAĞINI VEYA DEĞİŞTİRİLMEYECEĞİNİ GARANTİ ETMEMEKTEDİR.</p>`,
            section5: `<h2>Telif Hakkı Politikası.</h2>
<p>Başka kişilerin fikri mülkiyet haklarına saygı duyarız ve sitemizi kullanan kullanıcıların da aynısını yapmasını isteriz. Sitemizle bağlantılı olarak, herhangi bir ihlal edici materyalin kaldırılmasını ve telif hakları da dahil olmak üzere fikri mülkiyet haklarını tekrar tekrar ihlal eden kullanıcıların hizmetlerinin sona erdirilmesini sağlayan bir telif hakkı yasasına saygı politikası benimsemiş ve uygulamış bulunmaktayız. Eğer kullanıcılarımızdan birinin sitemizi kullanarak bir eserdeki telif hakkını yasa dışı bir şekilde ihlal ettiğine inanıyorsanız ve ihlal ettiği iddia edilen materyalin kaldırılmasını istiyorsanız, aşağıda yer alan bilgiler yazılı bir bildirim (17 U.S.C. § 512(c) uyarınca) şeklinde belirlenmiş Telif Hakkı Temsilcimize sağlanmalıdır:</p>
<p>- fiziksel veya elektronik imzanız;</p>
<p>- ihlal edildiğini iddia ettiğiniz telif hakkı ile korunan eserlerin tanımı;</p>
<p>- hizmetlerimizde ihlal ettiğini iddia ettiğiniz ve kaldırmamızı talep ettiğiniz materyalin tanımı;</p>
<p>- böyle bir materyali bulmamıza olanak tanıyacak yeterli bilgi;</p>
<p>- adresiniz, telefon numaranız ve e-posta adresiniz;</p>
<p>- itiraz edilen materyalin kullanımının telif hakkı sahibi, temsilcisi veya yasa tarafından yetkilendirilmediğine iyi niyetle inandığınıza dair bir beyan; ve</p>
<p>- bildirimde yer alan bilgilerin doğru olduğunu ve yeminli ifade cezası altında, ihlal edildiği iddia edilen telif hakkının sahibi olduğunuzu veya telif hakkı sahibinin adına hareket etmeye yetkili olduğunuzu belirten bir beyan.</p>
<p>Lütfen 17 U.S.C. § 512(f) uyarınca, yazılı bir bildirimde maddi gerçeğin yanlış beyan edilmesi durumunda, şikayet eden tarafın, yazılı bildirim ve telif hakkı ihlali iddiası ile bağlantılı olarak maruz kaldığımız herhangi bir zarar, maliyet ve avukatlık ücretlerinden otomatik olarak sorumlu olacağını unutmayın.</p>`,
            section6: `<h2>Genel</h2>
<p>Bu Şartlar zaman zaman revizyona tabi tutulabilir ve herhangi bir önemli değişiklik yaparsak, size son verdiğiniz e-posta adresine bir e-posta göndererek ve/veya Sitemizde değişiklik bildirimini belirgin bir şekilde yayınlayarak sizi bilgilendirebiliriz. Bize en güncel e-posta adresinizi sağlamaktan siz sorumlusunuz. Sağladığınız son e-posta adresi geçerli değilse, böyle bir bildirimi içeren e-postanın bizim tarafımızdan gönderilmesi yine de bildirimde tanımlanan değişikliklerin etkili bir bildirimi sayılacaktır. Bu Şartlardaki herhangi bir değişiklik, size e-posta bildirimi gönderilmesinden veya Sitemizdeki değişiklik bildirisinin yayınlanmasından otuz (30) takvim günü sonra veya yeni kullanıcılar için hemen yürürlüğe girecektir. Bu tür değişikliklerden sonra Sitemizi kullanmaya devam etmeniz, bu değişiklikleri kabul ettiğiniz ve bu değişikliklerin şartlarına bağlı olmayı kabul ettiğiniz anlamına gelecektir. Uyuşmazlık Çözümü. Lütfen bu Tahkim Anlaşmasını dikkatlice okuyun. Bu, bizimle yaptığınız sözleşmenin bir parçasıdır ve haklarınızı etkiler. ZORUNLU BAĞLAYICI TAHKİM VE grup DAVA FERAGATI için prosedürler içerir.</p>
<p><strong>Tahkim Anlaşmasının Uygulanabilirliği.</strong> Şartlarla bağlantılı tüm iddialar ve anlaşmazlıklar veya biz tarafından sağlanan herhangi bir ürün veya hizmetin kullanımı ile ilgili tüm iddialar ve ihtilaflar, küçük iddialar mahkemesinde veya gayri resmi olarak çözülemeyen tüm iddialar ve anlaşmazlıklar bu Tahkim Anlaşmasının şartlarına göre bireysel olarak bağlayıcı tahkim yoluyla çözülecektir. Aksi kararlaştırılmadıkça, tüm tahkim işlemleri İngilizce yapılacaktır. Bu Tahkim Anlaşması size ve bize, ve herhangi bir yan kuruluş, bağlı kuruluş, temsilci, çalışan, önceki kişiler, halefler ve atanmış kişilere, ayrıca Şartlar kapsamında sağlanan malların veya hizmetlerin tüm yetkili veya yetkisiz kullanıcılarına veya yararlanıcılarına uygulanır.</p>
<p><strong>Bildirim Gerekliliği ve Gayri Resmi Uyuşmazlık Çözümü.</strong> Taraflardan biri tahkim talebinde bulunmadan önce, diğer tarafa iddianın veya anlaşmazlığın niteliğini ve temelini tanımlayan ve talep edilen çözümünü belirten yazılı bir Uyuşmazlık Bildirimi göndermelidir. Bize gönderilen bir Bildirim bizim iletişim e-posta adresimize gönderilmelidir. Bildirim alındıktan sonra, siz ve biz iddia veya anlaşmazlığı gayri resmi olarak çözmeye çalışabiliriz. Siz ve biz, Bildirim alındıktan otuz (30) gün sonra iddia veya anlaşmazlığı çözmezse, taraflardan biri bir tahkim işlemine başlayabilir. Taraflardan herhangi biri tarafından yapılan herhangi bir uzlaşma teklifinin miktarı, hakem, taraflardan herhangi birinin hak kazanacağı ödül miktarını belirleyene kadar açıklanamaz.</p>
<p><strong>Tahkim Kuralları.</strong> Tahkim, bu bölümde belirtilen tahkim seçeneklerini sunan köklü bir alternatif uyuşmazlık çözüm sağlayıcısı olan Amerikan Tahkim Birliği (AAA) aracılığıyla başlatılacaktır. AAA tahkime hazır değilse, taraflar alternatif bir ADR Sağlayıcı seçmeyi kabul edeceklerdir. ADR Sağlayıcısının kuralları, bu kurallar Şartlarla çelişmediği sürece tahkimin tüm yönlerini yönetecektir. AAA Tüketici Tahkim Kuralları, adr.org adresinde çevrimiçi olarak veya 1-800-778-7879 numaralı telefonu arayarak AAA'dan temin edilebilir. Tahkim, tek bir tarafsız hakem tarafından yürütülecektir. Talep edilen ödülün toplam miktarının On Bin Amerikan Doları'ndan (10.000,00 ABD Doları) az olduğu herhangi bir iddia veya anlaşmazlık, talepte bulunan tarafın tercihine bağlı olarak bağlayıcı, fiziksel görünüm gerektirmeyen tahkim yoluyla çözülebilir. Talep edilen ödülün toplam miktarının On Bin Amerikan Doları (10.000,00 ABD Doları) veya daha fazla olduğu iddia veya anlaşmazlıklarda, bir duruşma hakkına Tahkim Kuralları tarafından karar verilecektir. Herhangi bir duruşma, taraflar aksini kabul etmedikçe, ikametgahınıza 100 mil uzaklıkta bir yerde yapılacaktır. ABD dışında ikamet ediyorsanız, hakem, taraflara herhangi bir sözlü duruşmanın tarih, saat ve yerini makul bir şekilde bildirecektir. Hakem tarafından verilen karar üzerindeki herhangi bir hüküm, yetkili bir yargı mercii mahkemesine sunulabilir. Eğer hakem, tahkimin başlatılmasından önce bizim size yaptığımız son uzlaşma teklifinden daha büyük bir ödül verir ise, size verilen ödül miktarını veya 2.500,00 ABD Doları tutarının hangisi daha büyükse onu ödeyeceğiz. Her taraf tahkimden kaynaklanan kendi masraflarını ve avanslarını üstlenecek ve ADR Sağlayıcısının ücret ve masraflarının eşit payını ödeyecektir.</p>
<p><strong>Görünüm Gerektirmeyen Tahkim İçin Ek Kurallar.</strong> Görünüm gerektirmeyen tahkim seçilirse, tahkim telefonla, çevrimiçi olarak ve/veya yalnızca yazılı sunumlara dayanarak yürütülecektir; spesifik yöntem tahkimi başlatan taraf tarafından seçilecektir. Tahkim tarafların veya tanıkların kişisel olarak bulunmasını içermeyecektir, aksi halde taraflar tarafından kabul edilmedikçe.</p>
<p><strong>Zaman Sınırları.</strong> Siz veya biz tahkim yoluna başvurursa, tahkim davasının ilgili talep için AAA Kuralları kapsamında belirlenen zaman aşımı süresi ve herhangi bir son tarih içerisinde başlatılması ve/veya talep edilmesi gerekmektedir.</p>
<p><strong>Hakemin Yetkisi.</strong> Tahkim başlatılırsa, hakem sizin ve bizim haklarınızı ve yükümlülüklerinizi belirleyecek ve anlaşmazlık başka herhangi bir meseleyle birleştirilmeyecek veya başka herhangi bir dava veya tarafla birleştirilmeyecektir. Hakem, herhangi bir talebin tamamını veya bir kısmını çözümlemeye yönelik talepleri kabul etme yetkisine sahip olacaktır. Hakem, parasal tazminatlar ve geçerli yasalar, AAA Kuralları ve Şartlar uyarınca bir birey için mevcut olan parasal olmayan herhangi bir çare veya yardım sağlama yetkisine sahip olacaktır. Hakem, ödülün dayandığı temel bulguları ve sonuçları açıklayan yazılı bir ödül ve karar beyanı yayınlayacaktır. Hakemin, yardım sağlama yetkisi, bir mahkeme yargıcının bireysel olarak sahip olacağı yetki ile aynıdır. Hakemin kararı sizin ve bizim için nihai ve bağlayıcıdır.</p>
<p><strong>Jüri Duruşmasından Feragat.</strong> TARAFALAR BURADA MAHKEMEYE GİTME VE BİR HÂKİM YA DA JÜRİ ÖNÜNDE DURUŞMA YAPMA KONUSUNDAKİ ANAYASAL VE YASAL HAKLARINDAN FERAGAT ETMEKTEDİR, bunun yerine tüm iddialar ve anlaşmazlıklar bu Tahkim Anlaşması kapsamında tahkim yoluyla çözülmesini seçmektedir. Tahkim prosedürleri genellikle daha sınırlı, daha verimli ve mahkemede geçerli kurallardan daha az maliyetlidir ve bir mahkeme tarafından çok sınırlı bir incelemeye tabidir. Siz ve biz arasında herhangi bir eyalet veya federal mahkemede bir tahkim ödülünü iptal etmek veya uygulamak için açılan bir davada veya başka bir şekilde herhangi bir dava açılması durumunda, SİZ VE BİZ TÜM JÜRİ YARGILAMASI HAKLARINDAN FERAGAT EDİYOR, bunun yerine anlaşmazlığın bir yargıç tarafından çözülmesini seçiyoruz.</p>
<p><strong>Sınıfsal veya Birleştirilmiş Davalardan Feragat.</strong> Bu tahkim anlaşması kapsamındaki tüm iddialar ve anlaşmazlıklar bireysel olarak tahkim edilmeli veya dava edilmelidir ve sınıfsal olarak yapılmamalıdır ve birden fazla müşterinin veya kullanıcının iddiaları başka herhangi bir müşteri veya kullanıcının iddiaları ile birlikte tahkim edilemez veya dava edilemez.</p>
<p><strong>Gizlilik.</strong> Tahkim sürecinin tüm yönleri kesinlikle gizli olacaktır. Taraflar, yasa tarafından aksini gerektirmedikçe gizliliği korumayı kabul ederler. Bu paragraf, bir tarafın bu Anlaşmayı uygulamak, bir tahkim kararını uygulamak veya tedbir talep etmek amacıyla bir mahkemeye herhangi bir bilgi sunmasını engellemez.</p>`,
            section7: `<p><strong>Bölünebilirlik.</strong> Bu Tahkim Anlaşmasının herhangi bir kısmının veya kısımlarının geçerli yasa uyarınca yetkili bir mahkeme tarafından geçersiz veya uygulanamaz bulunması durumunda, bu belirli kısım veya kısımlar geçersiz olacak ve ayrılacak ve Anlaşmanın geri kalan kısmı tam olarak geçerli olmaya devam edecektir.</p>
<p><strong>Feragat Hakkı.</strong> Bu Tahkim Anlaşmasında belirtilen hakların ve sınırlamaların herhangi biri veya tamamı, iddia edilen tarafça feragat edilebilir. Bu tür bir feragat, bu Tahkim Anlaşmasının diğer kısımlarını feragat veya etkilemez.</p>
<p><strong>Anlaşmanın Devamlılığı.</strong> Bu Tahkim Anlaşması, Sizinle olan ilişkinizin sona ermesinden sonra da devam edecektir.</p>
<p><strong>Küçük İddialar Mahkemesi.</strong> Yukarıdakilere rağmen, Siz veya Biz küçük iddialar mahkemesinde bireysel bir dava açabiliriz.</p>
<p><strong>Acil Durum Adil Yardım.</strong> Yukarıdakilere rağmen, taraflardan herhangi biri, tahkim sonuçlanmayı beklerken mevcut durumu korumak amacıyla eyalet veya federal mahkemeden acil durum adil yardım talep edebilir. Geçici tedbir talebi, bu Tahkim Anlaşması kapsamındaki diğer hak veya yükümlülüklerden feragat olarak kabul edilmeyecektir.</p>
<p><strong>Tahkime Tabi Olmayan İddialar.</strong> Yukarıdakilere rağmen, hakaret, Bilgisayar Dolandırıcılığı ve Kötüye Kullanım Yasası ihlali ve diğer tarafın patent, telif hakkı, ticari marka veya ticari sırlarının ihlali veya kötüye kullanılması iddiaları bu Tahkim Anlaşmasına tabi olmayacaktır.</p>
<p>Bu Tahkim Anlaşmasının tarafların mahkemede dava açmasına izin verdiği herhangi bir durumda, taraflar burada belirtilen amaçlar doğrultusunda California, Netherlands İlçesinde bulunan mahkemelerin kişisel yargı yetkisine tabi olmayı kabul ederler.</p>
<p>Site, ABD ihracat kontrol yasalarına tabi olabilir ve diğer ülkelerdeki ihracat veya ithalat düzenlemelerine tabi olabilir. We'den elde edilen ABD teknik verilerini veya bu verileri kullanan herhangi bir ürünü, ABD ihracat yasaları veya düzenlemelerine aykırı olarak doğrudan veya dolaylı olarak ihraç etmeme, yeniden ihraç etmeme veya aktarmama konusunda anlaşmaktasınız.</p>`,
            section8: `<p><strong>Elektronik İletişimler.</strong> Siz ve Biz arasındaki iletişimler, Siteyi kullanmanız veya bize e-posta göndermeniz, ya da Bizim Sitede bildirim yayınlamamız veya size e-posta yoluyla iletişim kurmamız gibi elektronik yöntemler kullanır. Sözleşmesel amaçlar için, siz (a) Bizden elektronik formda iletişim almayı kabul edersiniz; ve (b) Biz tarafından size elektronik olarak sağlanan tüm şartlar ve koşullar, anlaşmalar, bildirimler, açıklamalar ve diğer iletişimlerin yazılı olarak olması gereken herhangi bir yasal hükümlülüğü yerine getirdiğini kabul edersiniz.</p>
<p><strong>Tam Şartlar.</strong> Bu Şartlar, Sitenin kullanımı konusundaki sizinle bizim aramızdaki tüm anlaşmayı oluşturur. Bu Şartlardaki herhangi bir hakkı veya hükmü kullanmamamamız veya uygulamamamız, bu tür bir hak veya hükümden feragat ettiğimiz anlamına gelmez. Bu Şartlardaki bölüm başlıkları sadece kolaylık sağlamak amacıyla kullanılmıştır ve herhangi bir yasal veya sözleşmesel etkisi yoktur. "Dahil" kelimesi "sınırlama olmaksızın dahil" anlamına gelir. Bu Şartların herhangi bir hükmünün geçersiz veya uygulanamaz olduğu belirlenirse, bu Şartların diğer hükümleri etkilenmeyecek ve geçersiz veya uygulanamaz hüküm, yasanın izin verdiği azami ölçüde geçerli ve uygulanabilir olacak şekilde değiştirildiği kabul edilecektir. Bizimle olan ilişkiniz bağımsız bir yüklenici ilişkisidir ve taraflardan hiçbiri diğerinin temsilcisi veya ortağı değildir. Bu Şartlar ve bu şartlardaki haklarınız ve yükümlülükleriniz, Bizim önceden yazılı iznimiz olmadan tarafınızdan devredilemez, alt yükleniciye verilemez, yetkilendirilemez veya başka bir şekilde aktarılamaz ve yukarıdakilerin ihlali durumunda yapılan herhangi bir devredilme, alt yükleniciye verilme, yetkilendirilme veya aktarım geçersiz olacaktır. Bu Şartları serbestçe devredebiliriz. Bu Şartlarda belirtilen koşullar ve hükümler devralanlar için bağlayıcı olacaktır.</p>
<p><strong>Gizliliğiniz.</strong> Lütfen Gizlilik Politikamızı okuyun.</p>
<p><strong>Telif Hakkı/Ticari Marka Bilgisi.</strong> Telif Hakkı ©. Tüm hakları saklıdır. Sitede görüntülenen tüm ticari markalar, logolar ve hizmet markaları bizim veya üçüncü tarafların mülkiyetindedir. Bu Markaları, Bizim veya Markalara sahip olabilecek üçüncü tarafın önceden yazılı izni olmadan kullanmanıza izin verilmez.</p>
<h2>İletişim Bilgileri</h2>
<p>E-posta: watch_support@adflash.cn</p>`
        },
        privacy: {
            section1: `<p>Gizliliğinizi korumak bizim için önemlidir. Aşağıdaki açıklamanın, ürünlerimizin zaman zaman internet üzerinden bize sağlayabileceğiniz kişisel olarak tanımlanabilir bilgileri nasıl ele aldığını anlamanıza yardımcı olacağını umuyoruz.</p>
<p>Uygulamalarımız ve ilgili hizmetlerimiz temelinde, üçüncü taraflarca sağlanan özel işlevsel hizmetlerin kullanımını talep edebilirsiniz. Herhangi bir özel işlevsel hizmeti kaydolmak ve kullanmak isterseniz, lütfen bu tür hizmetlere yönelik hizmet şartlarını dikkatlice okuyun. Herhangi bir özel işlevsel hizmeti kabul ederek veya kullanarak, bu Sözleşmeyi ve bu özel işlevsel hizmetin hizmet şartlarını okuduğunuzu ve kabul ettiğinizi ve bunlara bağlı kalmayı kabul ettiğinizi kabul etmiş olursunuz.</p>
<p>Bazen talep ettiğiniz hizmetleri sağlamak için bilgilere ihtiyacımız var, bu gizlilik bildirimi ürünlerimize uygulanır.</p>
<h2>Ekibin Topladığı ve Aldığı Bilgiler.</h2>
<p>Yazılım ve Hizmetleri kullandığınızda, otomatik olarak aşağıdaki tür bilgileri toplayabilir ve depolayabiliriz:</p>
<p>1. Teknik Bilgi. Cihazınızla ilgili belirli teknik bilgiler, Yazılım ve Hizmetlerin ve cihazınıza yüklü diğer üçüncü taraf yazılım ve uygulamaların ("Diğer Yazılımlar") kullanımı, işletim sistemi ve Diğer Yazılımların türü, adı ve sürümü dahil, ancak bunlarla sınırlı olmamak üzere, Yazılım ve Hizmetler ile etkileşimleri, ISS (İnternet Servis Sağlayıcısı), konum (GPS), İnternet protokol (IP) adresi, anonim kullanıcı tanımlayıcıları, cihaz yapılandırması ve tanımlayıcıları (iOS'ta kullanılan Advertisers for Identifier (IDFA), Android'de kullanılan Advertising ID (Reklam Tanımlayıcısı) gibi), Yazılım ve Hizmetlerin sürümü ve Yazılım ve Hizmetlerin yapılandırması ve ayarları;</p>
<p>2. Kullanım Bilgisi. Cihazınızı, Yazılım ve Hizmetleri ve Diğer Yazılımları kullanımınız ve etkileşiminizle ilgili belirli kullanım bilgileri, örneğin, Yazılım ve Hizmetleri ve Diğer Yazılımları ne zaman ve nasıl kullandığınız gibi bilgiler. Örneğin, sahip olduğunuz uygulamalar ve kullandığınız uygulamalar ve bunları ne sıklıkta kullandığınız. Görüntülediğiniz, kullandığınız ve eriştiğiniz reklamlar, bunları nasıl kullandığınız ve bunlara verdiğiniz yanıtlar;</p>
<p>3. Sosyal Ağlar. Yazılım ve Hizmetler aracılığıyla sosyal ağlarda yaptığınız etkileşimler;</p>`,
            section2: `<p>4. Kayıt İle İlgili Bilgiler. Yazılım ve Hizmetlere kaydolduğunuzda verdiğiniz bilgiler (varsa), örneğin ad, ev veya iş adresleri, e-posta adresi, telefon ve faks numaraları, doğum tarihi ve cinsiyet;</p>
<p>5. Destek İle İlgili Bilgiler. Destek bölümümüz veya e-posta yoluyla bizimle iletişim kurarsanız, e-posta adresinizi, adınızı ve destekle bağlantılı olarak gönüllü olarak gönderdiğiniz herhangi bir bilgiyi de toplayabiliriz. Yazılım ve Hizmetleri kullandığınızda, Biz otomatik olarak anonim bir iç kullanıcı kimliği (User ID) oluştururuz, bu kimlik Yazılım ve Hizmetleri kullanımınıza atanır ve yukarıdaki bilgilerden herhangi biriyle birleştirilebilir.</p>
<p>6. Cihaz Bilgileri</p>
<p>Cihazınız hakkındaki bilgileri toplarız, bu bilgiler donanım modeli, işletim sistemi ve sürümü, benzersiz cihaz tanımlayıcılar (SSOID, GAID dahil), tarayıcı türü ve dili, mobil cihaz telefon numarası, ve mobil ağ bilgileri, Uluslararası Mobil Cihaz Kimliği (“IMEI”), Reklam Tanımlayıcısı (“IDFA”), Satıcı Tanımlayıcısı (“IDFV”), Entegre Devre Kartı Tanımlayıcısı (“ICCID”), Ortam Erişim Kontrolü (“MAC”) adresi, modeli ve çözünürlüğü içerir ve bu bilgiler ürünlerimizi ve Hizmetlerimizi kullanan cihazların sayısını hesaplamak ve cihaz modelleri ve grafik uyarlaması üzerindeki verileri analiz etmek için kullanılır. Belirli bilgileri vermemeyi seçebilirsiniz, ancak bu durumda uygulamalarımızın birçoğundan yararlanamayabilirsiniz. Ayrıca, Hizmetlerimizi kullanırken herhangi bir çökme veya başka bir sorun yaşamanız durumunda nadiren de olsa sorunları teşhis etmemize yardımcı olacak belirli cihaz bilgilerini de toplarız.</p>
<p>7. Kamera ve Fotoğraflar</p>
<p>Hizmetlerimizin birçoğu cihazınızın kamerasından ve fotoğraflarından görüntüleri ve diğer bilgileri toplamamızı gerektirir. Kamera ve fotoğraflarınıza sadece siz onay verdikten sonra erişiriz. Bu izni vermemeyi seçebilirsiniz, ancak bu durumda Hizmetlerimizin birçoğundan yararlanamayabilirsiniz.</p>
<p>8. Yerel depolama</p>
<p>Tarayıcı web depolaması (HTML 5 dahil) ve uygulama veri önbellekleri gibi mekanizmalar kullanarak cihazınızda yerel olarak bilgi toplayabilir ve depolayabiliriz. Depoladığımız bilgiler hiçbir üçüncü tarafla paylaşılmayacaktır.</p>
<p>9. KİŞİSEL OLMAYAN BİLGİLER</p>`,
            section3: `<p>Kişisel olmayan bilgiler, belirli bir bireyle doğrudan ilişkilendirilmesine izin vermeyen bir biçimde olan verilerdir, örneğin Android ID'niz, CPN modeliniz, bellek boyutunuz, telefon IMEI numaranız, telefon modeliniz, rom, yüklü uygulama adı ve paket adı, telefon operatörü, konum, kurulum, kaldırma, kullanım sıklığı vb.</p>
<p>Aşağıdaki durumlarda kişisel olmayan bilgiler toplayabilir ve kullanabiliriz. Kullanıcı davranışını daha iyi anlamak, ürün ve hizmetlerdeki sorunları çözmek, ürünlerimizi, hizmetlerimizi ve reklamlarımızı iyileştirmek için yüklü uygulama adı ve paket adı, kurulum verileri, kullanım sıklığı, ülke, ekipman ve kanal gibi kişisel olmayan bilgiler toplayabiliriz.</p>
<h2>Ekibin Bilgilerinizi Nasıl Kullanır?</h2>
<p>Ekibimiz, topladığı bilgileri aşağıdaki şekillerde analitik, ticari ve operasyonel amaçlar için kullanabilir:</p>
<p>1. Yazılım ve Hizmetleri işletmek ve güncellemek ve size mevcut ve yeni işlevsellikler ve özellikler sağlamak;</p>
<p>2. Yazılım ve Hizmetleri iyileştirmek ve özelleştirmek;</p>
<p>3. Size kişiselleştirilmiş içerik sağlamak, bunlar arasında Bizden veya iş ortaklarımızdan hedefe yönelik ilgi alanına dayalı reklamların iletilmesi de vardır;</p>
<p>4. Yazılım ve Hizmetlerin kalitesini ve işleyişini sürdürmek ve iyileştirmek;</p>
<p>5. Yazılım ve Hizmet güncellemeleri hakkında sizi bilgilendirmek;</p>
<p>6. Size destek sağlamak ve şikayetlerinizi veya geri bildirimlerinizi ele almak;</p>
<p>7. Yazılım ve Hizmetlerle ilgili olarak sizi içeren bir anlaşmazlık durumunda herhangi bir işlem yapmak;</p>`,
            section4: `<p>8. Yazılım ve Hizmetlere uygulanabilir şartlar ve koşulları uygulamak ve yasadışı faaliyetleri ve kötüye kullanımı önlemek;</p>
<p>9. Uygulanabilir herhangi bir yasal düzenlemeye, yasal sürece veya uygulanabilir hükümet talebine uymak ve tatmin etmek. Üçüncü Taraflarla Bilgi Paylaşma.</p>
<h2>Çocukların Gizliliği</h2>
<p>Bu Hizmetler 13 yaşın altındaki kişilere hitap etmez. 13 yaşın altındaki çocuklardan bilerek kişisel olarak tanımlanabilir bilgi toplamam. 13 yaşından küçük bir çocuğun bana kişisel bilgi sağladığını keşfedersem, bunu hemen sunucularımızdan silerim. Eğer bir ebeveyn veya veli iseniz ve çocuğunuzun bize kişisel bilgi sağladığını fark ederseniz, gerekli önlemleri alabilmem için lütfen benimle iletişime geçin.</p>
<h2>Bu Gizlilik Politikasındaki Değişiklikler</h2>
<p>Gizlilik Politikamızı zaman zaman güncelleyebilirim. Bu nedenle, bu sayfayı periyodik olarak herhangi bir değişiklik için incelemenizi tavsiye ederim. Yeni Gizlilik Politikasını bu sayfada yayınlayarak sizi herhangi bir değişiklikten haberdar edeceğim. Bu değişiklikler bu sayfada yayınlandıktan hemen sonra yürürlüğe girer.</p>
<h2>Bize Ulaşın</h2>
<p>Gizlilik Politikam ile ilgili herhangi bir sorunuz veya öneriniz varsa, .Email: watch_support@adflash.cn adresinden benimle iletişime geçmekten çekinmeyin.</p>`,


        },
        refund: {
            section1: `<p>Satın aldığınız için teşekkür ederiz. Satın alımınızdan tamamen memnun kalmazsanız, size yardımcı olmak isteriz.</p>
<p>İade sürecini başlatmak için lütfen aşağıdaki adımları izleyin.</p>
<p>Abonelik ürünleri (örneğin, Temel Abonelik)</p>
<p>Abonelik kullanmayı bırakır, ancak aboneliğinizi iptal etmeyi unutursanız, ödeme yapıldıktan sonra herhangi bir oturum kullanmadığınız veya herhangi bir görüntü oluşturmadığınız sürece, genellikle en son abonelik ödemenizi geri ödemekten memnuniyet duyarız.</p>
<p>İade talep etmek için lütfen hesabınız üzerinden ve geri bildirim formu aracılığıyla bize bir iade talebi gönderin. Kimliğinizi doğrulamamıza yardımcı olacak belirli bilgileri istememiz gerekebilir.</p>
<p>Dijital ürünler (örneğin, Zaman paketleri, abonelikler)</p>
<p>Herhangi bir Zaman paketi veya abonelik kullanılmışsa geri ödeme yapılmaz. Kısmi veya orantılı geri ödeme sağlamıyoruz.</p>
<p>İade talep etmek için işlemin gerçekleştiği tarihten itibaren 14 takvim gününüz vardır.</p>
<p>İade talebinizi aldığımızda, personelimiz iade almaya uygun olup olmadığınızı belirlemek için üyelik durumunuzu kontrol edecektir.</p>`,
            section2: `<p>Eğer onaylanırsa, iadeniz işleme alınacaktır. İade, satın alma işleminde kullanılan orijinal [ödeme yöntemi]ne yatırılacaktır.</p>
<p>Gecikmiş veya eksik iadeler</p>
<p>İadenizi almadıysanız, lütfen önce banka hesabınızı tekrar kontrol edin.</p>
<p>Sonra banka kartı şirketinizle iletişime geçin, iadenizin resmi olarak serbest bırakılması biraz zaman alabilir.</p>
<p>Ardından bankanızla iletişime geçin. Genellikle, iadenin serbest bırakılmadan önce biraz işlem süresi gerektirir.</p>
<p>Tüm bunları yaptıysanız ve hala iadenizi almadıysanız, lütfen watch_support@adflash.cn adresinden bizimle iletişime geçin.</p>
<p>Satış Ürünleri</p>
<p>Yalnızca normal fiyatlı öğeler iade edilebilir, maalesef indirimli ürünler iade edilemez.</p>`,
        },
        us: {
            section1: `<p>Şirket Bilgileri</p>
<p>Ad: HONG KONG ADFLASH MEDIA LIMITED</p>
<p>Adres: RM 1101 11/F SAN TOI BUILDING NO.139 CONNAUGHT ROAD CENTRAL HK</p>
<p>E-posta: watch_support@adflash.cn</p>`,
        },
    }
}