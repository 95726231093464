module.exports = {
    language: "丹麦语",


    setting: {
        index: {
            title: "Indstilling",
            problem: "Problemtilbagemelding",
            order: "Bestillingshistorik",
            notice: "Besked",
            language: "Sprog",
            clear: "Ryd Cache",
            clearTip: "Vil du bekræfte \n ryd cachen?",
            confirm: "Bekræft",
            cancel: "Annuller",
        },
        language: {
            title: "Sprog",
            langList: ["Forenklet kinesisk", "traditionelt kinesisk", "engelsk", "arabisk", "Polere", "persisk", "dansk", "tysk", "Russisk", "fransk", "hollandsk", "tjekkisk", "rumænsk", "portugisisk", "japansk", "Thai", "tyrkisk", "spansk", "græsk", "italiensk", "vietnamesisk", "hindi"],
        },
        order: {
            title: "Bestillingshistorik",
            success: "Få Succes",
            unpaid: "Ubetalt",
            paid: "Betalt",
            expired: "Udløbet",
            refunded: "Refunderet",
            pTime: "Betalingstid",
            eTime: "Udløbstid",
            pMethod: "Betalingsmetode",
            pObject: "Betalingsobjekt",
            oNumber: "Ordrenummer",
            pType: "Pakke Type",
            oTime: "Erhvervelsestid",
            watch: "Se IMEI",
            delete: "Slet Ordre",
            refund: "Anmod om refusion",
            empty: "Ingen optegnelse endnu",
            grade: "Pakke niveau",
            package: "Pakke status",
            use: "Brugsstatus",
            remaining: "Pakke saldo",
            basic: "Normal",
            pro: "Avanceret",
            refundAmount: "Refusionsbeløb"
        },
        problem: {
            title: "Problemtilbagemelding",
            feedback: "Feedback Type",
            please: "Vælg venligst",
            content: "Indhold",
            improve: "Skriv dine forslag, såsom funktionskrav, produktklager osv. Vi vil arbejde hårdt for at forbedre",
            upload: "Upload billede",
            submit: "Indsend",
            general: "Generel Feedback",
            purchase: "Køberelateret",
            bug: "Fejlrapport",
            suggestion: "Forslag",
            other: "Andet",
        },
        notice: {
            title: "Besked",
            type: "Meddelelsestype: Systemsvar",
            time: "Beskedstid: "
        }
    },
    subscription: {
        jarvis: {
            funcList: ["AI Spørgsmål og svar", "AI tegning", "Grundlæggende version af Smart AI", "Avanceret Smart AI", "Multimodal ind- og udgang", "Mere detaljerede spørgsmål og svar", "Intelligent modeller", "Hurtigere respons"],
            times: "Gange",
            days: "Dage",
            more: "Mere avanceret AI",
            custom: "Brugerdefineret Smart AI",
            delay: "Der vil være en lille forsinkelse i krediteringen af din konto.Vent venligst og luk ur-app'en og gå ind i den igen.",
            member: "Medlems sammenligning",
            packageName: "Pakke navn",
            unlimited: "ubegrænset",
            month: "Måned",
            },
        index: {
            nav: {
                l: "Pro",
                r: "Tid",
                language: "Sprog",
                notice: "Besked",
                feedback: "Feedback"
            },
            proBox: {
                expire: {
                    title: "Udløbsdato",
                    date: "Ikke abonneret endnu",
                },
                packTip: "Vi har en række forskellige månedlige pakker",
                packList: ["Supreme Abonnement", "Basic Abonnement", "Normal Abonnement"],
                benefit: {
                    origin: "Original pris: ",
                    remaining: "Resterende",
                    title: "Abonnementsfordele",
                    value: "værdi",
                    benefitList: [
                        { labelTime: "Ubegrænset antal gange", labelSave: "Billedgemning", labelPer: "Fulde tilladelser" },
                        { labelTime: "300 gange", labelSave: "Optagelseslagring", labelPer: "Grundlæggende tilladelser" },
                        { labelTime: "100 gange", labelSave: "Optagelseslagring", labelPer: "Lave tilladelser" },
                    ],
                    desc: "$3.99/Måned kommer med 100 gratis brug, $9.99/Måned kommer med 300 gratis brug.",
                    tip: "Der vil være en lille forsinkelse i antallet af gange, der krediteres din konto. Vent venligst et øjeblik og afslut ur-appen og genindgang den."
                },
            },
            timeBox: {
                packTip: "Vi har en række forskellige månedlige pakker",
                packList: ["Ny bruger rabat Times pakke", "50 Times pakke", "100 Times pakke", "300 Times pakke"],
                times: {
                    title: "Times' rolle",
                    timesList: ["Kan bruges til tegning", "Tilgængelig til GPT Q&A", "Bruges til horoskop-spådom", "Kan bruges til læring", "Bruges til at genkende objekter",],
                    desc: "50 gange brug for $2.99, 100 gange brug for $4.99, 300 gange brug for $12.99. $0.99 50 gange pakke er for nye brugere og kan kun købes én gang (tilgængelig inden for 24 timer)",
                }
            },
            staticBox: {
                confirm: "Bekræft",
                privacy: "Privatliv",
                terms: "Betingelser",
                refund: "Refusion",
                cancel: "Annuller når som helst",
                funcTitle: "Frigør din kreativitet med PRO oplevelse",
                function: "Funktion",
                free: "Gratis",
                pro: "Pro",
                funcList: ["Times er gyldig i én dag", "10 Times Tegn/Chat (Tre chancer til at aktivere)", "Abonner én gang i 30 dage", "Flere Times til at bruge", "Billeder gemt for evigt", "Antallet af gange du kan købe"],
                details: "Detaljer",
            },
            dialog: {
                error: "Betaling er ikke tilgængelig. Scan venligst QR-koden på dit ur.",
                unbind: "Uret er blevet bundet til en betalingsmetode. Vil du ophæve bindingen?",
                beforePay: "Vil du fortsætte med at betale med den løbende konto?",
                renewal: "Om automatisk fornyelse skal aktiveres",
                confirm: "Bekræft",
                cancel: "Annuller",
                change: "Skifte/Bind",
                beforeConsent: "Du har abonneret på dette produkt",
            }
        },
        NewIndex:{
            l:"Basis",
            r:"Pro",
            proBox:{
                benefit:{
                    title:"Pakke inkluderer",
                    benefitList:[
                        {labelTime: "Få tider",labelSave: "normale tider"},
                        {labelTime: "Få tider",labelSave: "Pro tider"},
                        {labelTime: "Funktion låst op",labelSave: "Lås op for alle normale funktioner"},
                        {labelTime: "Funktion låst op",labelSave: "Lås op for alle premium-funktioner"},
                    ]
                }
            },
            funcList: ["Q&A", "AI-tegning", "Basic version Smart AI", "Avanceret Smart AI", "Tekst-til-tale", "Mere detaljerede svar", "Hurtigere svar"],
            confirm:'Opgrader nu',
            dialog:{
                beforePay: "Du har abonneret på det almindelige medlemskab. Hvis du fortsætter med at købe det premium medlemskab, vil du blive refunderet i henhold til det faktiske forbrug af det almindelige medlemskab. Vil du fortsætte?",
            }
        },
        unsubscribe: {
            title: "Kontinuerlig Abonnement",
            subTitle: "Abonnerer",
            unsubscribe: "Afmeld",
            subscribeTime: "Abonnementstid",
        },
        refund: {
            title: "Anmod om tilbagebetaling",
            number: "Ordrenummer",
            content: "Forklar venligst årsagen til tilbagebetalingen",
            empty: "Kan ikke indsende, obligatoriske felter er tomme.",
        },
        order: {
            number: "Ordrenummer",
            name: "Ordrenavn",
            times: "Ordretider",
            validity: "Gyldighed",
            buy: "Køb nu",
            days: "Antal dage",
            unlock: "Lås op for al intelligent AI",
            text: "Tekst til tale",
            tips: "Dette er et abonnementsprodukt. Efter køb starter abonnementet som standard.",
        },
        zhzn: {
            title: "Medlemsprivilegier",
            t1: "Ubegrænset Q&A",
            d1: "Ubegrænset brug af Q&A chatfunktion under medlemskab",
            t2: "AI tegning",
            d2: "Lås op for AI tegning tilladelser, kan udføre tekst tegning funktion",
            t3: "Udrejse indstillinger",
            d3: "Udrejsen kan indstilles efter AI tegningeresultater",
        }
    },
    payment: {
        index: {
            contact: "Kontaktinformation",
            please: "Indtast venligst din e-mailadresse",
            email: "Betalingskvittering vil blive sendt til denne e-mail",
            name: "Navn på kort",
            firstName: "Fornavn",
            lastName: "Efternavn",
            confidence: "Vi beholder ingen af dine oplysninger, indtast venligst med tillid",
            Card: "Kortinformation",
            confirm: "Ved at bekræfte dit abonnement tillader du Jarvis at debitere dit kort for fremtidige betalinger i overensstemmelse med deres betingelser. Du kan annullere dit abonnement til enhver tid.",
            powered: "Drevet af Stripe",
            terms: "Betingelser",
            privacy: "Privatliv",
        }
    },
    policy: {
        title: {
            terms: "Betingelser",
            about: "Om",
            service: "Servicevilkår",
            privacy: "Privatlivspolitik",
            refund: "Refusionspolitik",
            us: "Om Os",
        },
        service: {
            section1: `<p>Webstedet https://aiwatchstore.org/ er et ophavsretligt beskyttet arbejde tilhørende HONG KONG ADFLASH MEDIA LIMITED. Visse funktioner på webstedet kan være underlagt yderligere retningslinjer, vilkår eller regler, som vil blive offentliggjort på webstedet i forbindelse med sådanne funktioner.</p>
<p>Alle sådanne yderligere vilkår, retningslinjer og regler er indarbejdet ved reference i disse vilkår.</p>
<p>Disse brugsbetingelser beskriver de juridisk bindende vilkår og betingelser, der overvåger din brug af webstedet. VED AT LOGGE IND PÅ WEBSTEDET, ER DU I OVERENSSTEMMELSE MED DISSE VILKÅR og du erklærer, at du har autoritet og kapacitet til at indgå i disse vilkår. DU SKAL VÆRE MINDST 18 ÅR GAMMEL FOR AT FÅ ADGANG TIL WEBSTEDET. HVIS DU ER UENIG MED ALLE BESTEMMELSERNE I DISSE VILKÅR, LOGG IKKE IND OG/ELLER BRUG IKKE WEBSTEDET.</p>
<p>Disse vilkår kræver brugen af ​​voldgift i afsnit 10.2 på individuelt grundlag til at løse tvister og begrænser også de retsmidler, der er tilgængelige for dig i tilfælde af en tvist.</p>
<h2>Adgang til webstedet</h2>
<p><strong>Underlagt disse vilkår.</strong> Vi giver dig en ikke-overdragelig, ikke-eksklusiv, tilbagekaldelig, begrænset licens til at få adgang til webstedet udelukkende til din egen personlige, ikke-kommercielle brug.</p>
<p>Vi forbeholder os retten til at ændre, suspendere eller ophøre med webstedet med eller uden varsel til dig. Du accepterer, at vi ikke vil blive holdt ansvarlige over for dig eller nogen tredjepart for enhver ændring, afbrydelse eller ophør af webstedet eller nogen del heraf.</p>
<p><strong>Ingen support eller vedligeholdelse.</strong> Du accepterer, at vi ikke har nogen forpligtelse til at yde dig nogen support i forbindelse med webstedet.</p>
<p>Med undtagelse af ethvert brugerindhold, som du måtte levere, er du klar over, at alle immaterielle rettigheder, inklusive ophavsret, patenter, varemærker og forretningshemmeligheder, på webstedet og dets indhold ejes af os eller vores leverandører. Bemærk, at disse vilkår og adgang til webstedet ikke giver dig nogen rettigheder, ejerskab eller interesser i eller til nogen immaterielle rettigheder, undtagen for de begrænsede adgangsrettigheder, der er udtrykt i afsnit 2.1. Vi og vores leverandører forbeholder os alle rettigheder, der ikke er givet i disse vilkår.</p>`,
            section2: `<h2>Abonnementsvilkår</h2>
<p>Velkommen til vores abonnementstjeneste (herefter benævnt "Tjenesten"). Denne tjeneste leveres af HONG KONG ADFLASH MEDIA LIMITED (herefter benævnt "vi" eller "Selskabet"). Ved at bruge eller abonnere på vores tjeneste, accepterer du at overholde disse Abonnementsvilkår (herefter benævnt "Vilkårene").</p>
<p>Vi vil give dig abonnementsbaserede tjenester, inklusive eksklusive bruger-kvoter. De specifikke tjenestetilbud og gebyrer kan ændre sig til enhver tid og vil blive opdateret gennem meddelelser via e-mail eller på vores websted.</p>
<p>Abonnementsgebyrer, betalingsmetoder og betalingscyklusser er beskrevet på prissiden. Konti med forfaldne betalinger vil få deres betalte tjenester midlertidigt suspenderet.</p>
<p>Alle abonnementsgebyrer er ikke-refunderbare, medmindre det kræves ved lov eller efter vores eget skøn.</p>
<p>Du kan til enhver tid annullere dit abonnement. Ved annullering vil vi fortsætte med at levere tjenester indtil udgangen af den aktuelle abonnementsperiode, men der vil ikke blive udstedt refusion for allerede betalte gebyrer.</p>
<p>Vi forbeholder os også retten til ensidigt at ophæve eller suspendere din tjeneste under følgende omstændigheder:</p>
<p>- Overtrædelse af disse Vilkår</p>
<p>- Manglende betaling af abonnementsgebyrer</p>
<p>Vi vil gøre vores bedste for at levere tjenester af høj kvalitet, men vi giver ingen udtrykkelige eller underforståede garantier vedrørende tilgængeligheden, nøjagtigheden eller fuldstændigheden af tjenesten.</p>`,
            section3: `<h2>Andre brugere</h2>
<p><strong>Andre brugere.</strong> Hver bruger af webstedet er eneansvarlig for alt sit eget brugerindhold. Da vi ikke kontrollerer brugerindhold, anerkender og accepterer du, at vi ikke er ansvarlige for noget brugerindhold, hvad enten det leveres af dig eller andre. Du accepterer, at vi ikke vil være ansvarlige for noget tab eller skade, der opstår som et resultat af sådanne interaktioner. Hvis der opstår en tvist mellem dig og en anden bruger på webstedet, er vi ikke forpligtede til at blive involveret.</p>
<p>Du frigiver hermed og fritager for altid os og vores officerer, ansatte, agenter, efterfølgere og allokeringer fra og giver hermed afkald på hver og enhver tidligere, nuværende og fremtidig tvist, krav, kontrovers, krav, ret, forpligtelse, ansvar, handling og årsag til handling af enhver art og natur, som er opstået eller opstår direkte eller indirekte ud af, eller som relaterer direkte eller indirekte til, webstedet. Hvis du er bosiddende i Californien, giver du hermed afkald på California civil code section 1542 i forbindelse med det foregående, som siger: "en generel frigivelse udvides ikke til krav, som kreditoren ikke ved eller mistænker at eksistere til hans eller hendes fordel på tidspunktet for udførelsen af frigivelsen, som, hvis kendt af ham eller hende, skal have påvirket væsentligt hans eller hendes forlig med skyldneren."</p>
<p><strong>Cookies og Web Beacons.</strong> Ligesom enhver anden hjemmeside bruger 'cookies'. Disse cookies bruges til at gemme oplysninger, herunder besøgendes præferencer, og de sider på hjemmesiden, som den besøgende har fået adgang til eller besøgt. Oplysningerne bruges til at optimere brugernes oplevelse ved at tilpasse vores websideindhold baseret på besøgendes browsertype og/eller andre oplysninger.</p>
<h2>Ansvarsfraskrivelser</h2>
<p>Webstedet leveres på en "som den er" og "som tilgængelig" basis, og vi og vores leverandører fraskriver sig udtrykkeligt enhver og alle garantier og betingelser af enhver art, hvad enten de er udtrykkelige, underforståede eller lovbestemte, herunder alle garantier eller betingelser for salgbarhed, egnethed til et bestemt formål, ejendom, roligt nydelse, nøjagtighed eller ikke-krænkelse. Vi og vores leverandører garanterer ikke, at webstedet vil opfylde dine krav, vil være tilgængeligt på en uafbrudt, rettidig, sikker eller fejlfri måde eller vil være nøjagtigt, pålideligt, frit for virusser eller anden skadelig kode, komplet, lovligt eller sikkert. Hvis gældende lov kræver nogen garantier med hensyn til webstedet, er alle sådanne garantier begrænset i varighed til halvfems (90) dage fra den første brugsdato.</p>
<p>Nogle jurisdiktioner tillader ikke udelukkelse af underforståede garantier, så ovenstående udelukkelse gælder muligvis ikke for dig. Nogle jurisdiktioner tillader ikke begrænsninger for, hvor længe en underforstået garanti varer, så ovenstående begrænsning gælder muligvis ikke for dig.</p>`,
            section4: `<h2>Ansvarsbegrænsning</h2>
<p>Til det maksimale omfang loven tillader, må vi eller vores leverandører under ingen omstændigheder være ansvarlige over for dig eller nogen tredjepart for tabt fortjeneste, tabte data, omkostninger ved indkøb af erstatningsprodukter eller nogen indirekte, konsekvensrige, eksemplariske, utilsigtede, særlige eller strafbare skader, der opstår fra eller er relateret til disse vilkår eller din brug af eller manglende evne til at bruge webstedet, selvom vi er blevet informeret om muligheden for sådanne skader. Adgang til og brug af webstedet er på eget ansvar, og du er eneansvarlig for enhver skade på din enhed eller computersystem eller tab af data deraf.</p>
<p>Til det maksimale omfang loven tillader, til trods for noget modstridende heri, vil vores ansvar over for dig for eventuelle skader, der opstår fra eller er relateret til denne aftale, til enhver tid være begrænset til maksimalt halvtreds amerikanske dollars (us $50). Eksistensen af mere end et krav vil ikke forøge denne grænse. Du accepterer, at vores leverandører ikke har noget ansvar af nogen art, der opstår fra eller er relateret til denne aftale.</p>
<p>Nogle jurisdiktioner tillader ikke begrænsning eller udelukkelse af ansvar for utilsigtede eller følgeskader, så ovenstående begrænsning eller udelukkelse gælder muligvis ikke for dig.</p>
<p><strong>Gyldighed og ophør.</strong> Med forbehold af dette afsnit vil disse vilkår forblive i fuld kraft og effekt, mens du bruger webstedet. Vi kan suspendere eller ophæve dine rettigheder til at bruge webstedet til enhver tid af enhver årsag efter vores eget skøn, herunder for enhver brug af webstedet i strid med disse vilkår. Ved ophør af dine rettigheder i henhold til disse vilkår vil din konto og ret til adgang til og brug af webstedet straks ophøre. Du forstår, at enhver ophør af din konto kan involvere sletning af dit brugerindhold forbundet med din konto fra vores live databaser. Vi vil ikke have noget ansvar over for dig for nogen ophør af dine rettigheder under disse vilkår. Selv efter dine rettigheder i henhold til disse vilkår ophører, vil følgende bestemmelser i disse vilkår forblive i kraft: Afsnit 2 til 2.5, Afsnit 3 og Afsnit 4 til 10.</p>
<p></p>
<p>Erstatning. Du vil forsvare, erstatte og holde os, vores tilknyttede selskaber og vores personale, skadesløse fra og mod enhver form for krav, tab og udgifter (herunder advokatsalærer) som følge af eller relateret til din brug af tjenesterne, herunder dit indhold, produkter eller tjenester, du udvikler eller tilbyder i forbindelse med tjenesterne, og dit brud på disse vilkår eller overtrædelse af gældende lovgivning.</p>
<p>Ansvarsfraskrivelse. TJENESTERNE LEVERES "SOM DE ER." BORTSET FRA HVOR DET ER FORBUDT VED LOV, GIVER VI OG VORES TILKNYTTEDE SELSKABER OG LICENSGIVERE INGEN GARANTIER (UDTRYKKELIGE, UNDERFORSTÅEDE, LOVBESTEMTE ELLER ANDET) MED HENSYN TIL TJENESTERNE, OG FRASKRIVER ALLE GARANTIER, HERUNDER MEN IKKE BEGRÆNSET TIL GARANTIER OM SALGBARHED, EGNETHED TIL ET BESTEMT FORMÅL, TILFREDSSTILLENDE KVALITET, IKKE-KRÆNKELSE OG RO og NYDELSE, OG ENHVER GARANTI, DER OPSTÅR UD AF EVENTUEL VIDEREBEHANDLING ELLER HANDELSBRUG. VI GARANTERER IKKE, AT TJENESTERNE VIL VÆRE UAFBRUDTE, NØJAGTIGE ELLER FEJLFRIE, ELLER AT NOGET INDHOLD VIL VÆRE SIKKER ELLER IKKE MISTET ELLER ÆNDRET.</p>`,
            section5: `<h2>Ophavsretspolitik.</h2>
<p>Vi respekterer andres immaterielle rettigheder og beder brugerne af vores websted om at gøre det samme. I forbindelse med vores websted har vi vedtaget og implementeret en politik vedrørende ophavsretsloven, der indeholder bestemmelser om fjernelse af ethvert krænkende materiale og om ophævelse af brugere af vores online websted, der gentagne gange krænker immaterielle rettigheder, herunder ophavsrettigheder. Hvis du mener, at en af vores brugere gennem brugen af ​​vores websted ulovligt krænker ophavsretten i et værk og ønsker at få det påståede krænkende materiale fjernet, skal følgende oplysninger i form af en skriftlig meddelelse (i henhold til 17 U.S.C. § 512(c)) gives til vores udpegede ophavsretsagent:</p>
<p>- din fysiske eller elektroniske underskrift;</p>
<p>- identifikation af de ophavsretligt beskyttede værker, som du hævder er blevet krænket;</p>
<p>- identifikation af det materiale på vores tjenester, som du hævder krænker, og som du anmoder om, at vi fjerner;</p>
<p>- tilstrækkelige oplysninger til at give os mulighed for at finde dette materiale;</p>
<p>- din adresse, telefonnummer og e-mailadresse;</p>
<p>- en erklæring om, at du i god tro mener, at brugen af ​​det omstridte materiale ikke er godkendt af ophavsretsejeren, dennes agent eller i henhold til loven; og</p>
<p>- en erklæring om, at oplysningerne i meddelelsen er korrekte, og under strafansvar for mened, at du enten er ejer af ophavsretten, der angiveligt er blevet krænket, eller at du er autoriseret til at handle på vegne af ophavsretsejeren.</p>
<p>Bemærk venligst, at i henhold til 17 U.S.C. § 512(f) vil enhver urigtig fremstilling af et faktisk forhold i en skriftlig meddelelse automatisk medføre, at den klagende part er ansvarlig for enhver skade, omkostninger og advokatsalærer, vi pådrager os i forbindelse med den skriftlige meddelelse og anklagen om ophavsretskrænkelse.</p>`,
            section6: `<h2>Generelt</h2>
<p>Disse vilkår er underlagt lejlighedsvise revisioner, og hvis vi foretager nogen væsentlige ændringer, kan vi underrette dig ved at sende dig en e-mail til den sidste e-mailadresse, du har givet os, og/eller ved at fremtrædende offentliggøre en meddelelse om ændringerne på vores websted. Du er ansvarlig for at give os din mest opdaterede e-mailadresse. I tilfælde af at den sidste e-mailadresse, du har givet os, ikke er gyldig, vil vores udsendelse af e-mailen, der indeholder en sådan meddelelse, alligevel udgøre en effektiv meddelelse om de ændringer, der er beskrevet i meddelelsen. Enhver ændring af disse vilkår vil træde i kraft senest tredive (30) kalenderdage efter vores udsendelse af en e-mailmeddelelse til dig eller tredive (30) kalenderdage efter vores offentliggørelse af en meddelelse om ændringerne på vores websted. Disse ændringer vil træde i kraft med det samme for nye brugere af vores websted. Fortsat brug af vores websted efter meddelelse om sådanne ændringer skal indikere din anerkendelse af sådanne ændringer og accept af at være bundet af vilkårene og betingelserne for sådanne ændringer. Tvistbilæggelse. Læs venligst denne voldgiftsaftale omhyggeligt. Det er en del af din kontrakt med os og påvirker dine rettigheder. Den indeholder procedurer for MANDATORISK BINDENDE VOLDSGIFT OG ET klasseNavnAFTALONTSKILLE.</p>
<p><strong>Anvendelighed af Voldgiftsaftale.</strong> Alle krav og tvister i forbindelse med vilkårene eller brugen af ethvert produkt eller tjeneste leveret af os, som ikke kan løses uformelt eller i småkravsdomstolen, skal afgøres ved bindende voldgift på individuel basis i henhold til betingelserne i denne Voldgiftsaftale. Medmindre andet er aftalt, skal alle voldgiftsprocedurer afholdes på engelsk. Denne Voldgiftsaftale gælder for dig og os, og for eventuelle datterselskaber, tilknyttede selskaber, agenter, medarbejdere, forgængere i interesse, efterfølgere og tildelinger, såvel som alle autoriserede eller uautoriserede brugere eller modtagere af tjenester eller varer leveret i henhold til vilkårene.</p>
<p><strong>Krav om meddelelse og uformel tvistbilæggelse.</strong> Før nogen af parterne kan søge voldgift, skal parten først sende den anden part en skriftlig meddelelse om tvist, der beskriver karakteren og begrundelsen for kravet eller tvisten samt den anmodede godtgørelse. En meddelelse til os skal sendes til vores kontakt-e-mail. Efter at meddelelsen er modtaget, kan du og vi forsøge at løse kravet eller tvisten uformelt. Hvis du og vi ikke løser kravet eller tvisten inden for tredive (30) dage efter meddelelsen er modtaget, kan hver part begynde en voldgiftsprocedure. Beløbet for ethvert forligstilbud, der er givet af nogen af parterne, må ikke oplyses til voldgiftsmanden, før efter voldgiftsmanden har fastlagt beløbet for den pris, som hver part er berettiget til.</p>
<p><strong>Voldgiftsregler.</strong> Voldgift skal indledes gennem American Arbitration Association, en etableret udbyder af alternativ konfliktløsning, der tilbyder voldgift som anført i dette afsnit. Hvis AAA ikke er tilgængelig til at voldgifte, skal parterne blive enige om at vælge en alternativ ADR-udbyder. ADR-udbyderens regler skal regulere alle aspekter af voldgiften, undtagen i det omfang sådanne regler er i konflikt med vilkårene. De AAA Forbrugervoldgiftsregler, der gælder for voldgiften, er tilgængelige online på adr.org eller ved at ringe til AAA på 1-800-778-7879. Voldgiften skal gennemføres af en enkelt, neutral voldgiftsmand. Eventuelle krav eller tvister, hvor det samlede beløb for det efterspurgte krav er mindre end ti tusind amerikanske dollars (US $10.000,00) kan løses gennem bindende voldgift uden fremmøde, efter anmodning fra den part, der søger afhjælpning. For krav eller tvister, hvor det samlede beløb for det efterspurgte krav er ti tusind amerikanske dollars (US $10.000,00) eller mere, vil retten til en høring blive bestemt af voldgiftsreglerne. Enhver høring vil blive afholdt på en placering inden for 100 miles fra din bopæl, medmindre du bor uden for USA, og medmindre parterne bliver enige om andet. Hvis du bor uden for USA, skal voldgiftsmanden give parterne rimelig varsel om dato, tid og sted for eventuelle mundtlige høringer. Enhver dom på den tildelte voldgiftskendelse kan indføres i enhver domstol med kompetent jurisdiktion. Hvis voldgiftsmanden tildeler dig en erstatning, der er større end det sidste forligstilbud, vi fremsatte til dig før indledning af voldgiften, vil vi betale dig enten det større af erstatningen eller $2.500,00. Hver part skal bære sine egne omkostninger og udgifter i forbindelse med voldgiften og skal betale en lige andel af gebyrerne og omkostningerne til ADR-udbyderen.</p>
<p><strong>Yderligere regler for ikke-fremmødebaseret voldgift.</strong> Hvis ikke-fremmødebaseret voldgift vælges, skal voldgiften gennemføres via telefon, online og/eller alene baseret på skriftlige indlæg; den specifikke metode skal vælges af den part, der indleder voldgiften. Voldgiften må ikke involvere nogen personlig fremmøde af parterne eller vidner, medmindre andet er aftalt af parterne.</p>
<p><strong>Tidsfrister.</strong> Hvis du eller vi forfølger voldgift, skal voldgiftshandlingen indledes og/eller kræves inden for forældelsesfristen og inden for enhver frist, der er fastsat i AAA-reglerne for det relevante krav.</p>
<p><strong>Voldgiftsmandens myndighed.</strong> Hvis voldgift indledes, vil voldgiftsmanden beslutte dine og vores rettigheder og forpligtelser, og tvisten vil ikke blive konsolideret med andre sager eller forenet med andre sager eller parter. Voldgiftsmanden skal have myndighed til at give beslutninger, der afgør hele eller dele af et krav. Voldgiftsmanden skal have myndighed til at tilkende økonomisk erstatning og give enhver ikke-økonomisk retsmiddel eller afhjælpning til rådighed for en person under gældende lov, AAA-reglerne og vilkårene. Voldgiftsmanden skal afgive en skriftlig kendelse og beslutningsudtalelse, der beskriver de væsentlige konklusioner og konklusioner, hvorpå kendelsen er baseret. Voldgiftsmanden har samme myndighed til at tildele afhjælpning på individuel basis, som en dommer i en domstol ville have. Voldgiftsmandens afgørelse er endelig og bindende for dig og os.</p>
<p><strong>Afkald på nævningesag.</strong> PARTERNE GIVER HERMED AFKALD PÅ DERES FORFATNINGSMÆSSIGE OG LOVBESTEMTE RETTIGHEDER TIL AT GÅ I RETTEN OG FÅ EN RETSSAG FORAN EN DOMMER ELLER EN NÆVNINGE, i stedet vælger de, at alle krav og tvister skal løses ved voldgift i henhold til denne voldgiftsaftale. Voldgiftsprocedurer er typisk mere begrænsede, mere effektive og mindre omkostningstunge end regler, der gælder i en domstol og er underlagt meget begrænset gennemgang af en domstol. I tilfælde af at der opstår retssager mellem dig og os i en statslig eller føderal domstol i en sag for at ophæve eller håndhæve en voldgiftskendelse eller på anden måde, FRASKRIVER DU OG VI OS ALLE RETTIGHEDER TIL EN NÆVNINGESAG, og vælger i stedet, at tvisten løses af en dommer.</p>
<p><strong>Afkald på klasse- eller konsoliderede handlinger.</strong> Alle krav og tvister inden for denne voldgiftsaftales anvendelsesområde skal voldgives eller retsforfølges på individuel basis og ikke på klassebasis, og krav fra mere end én kunde eller bruger kan ikke voldgives eller retsforfølges i fællesskab eller konsolideres med dem fra nogen anden kunde eller bruger.</p>
<p><strong>Fortrolighed.</strong> Alle aspekter af voldgiftsproceduren skal være strengt fortrolige. Parterne er enige om at opretholde fortroligheden, medmindre det kræves af loven. Dette afsnit forhindrer ikke en part i at indsende enhver nødvendig information til en domstol for at håndhæve denne aftale, for at håndhæve en voldgiftskendelse eller for at søge en retslig eller retfærdig afhjælpning.</p>`,
            section7: `<p><strong>Adskillelighed.</strong> Hvis nogen del eller dele af denne voldgiftsaftale findes under loven at være ugyldige eller uden retskraft af en domstol med kompetent jurisdiktion, så skal sådan specifik del eller dele ikke have nogen virkning og skal adskilles, og resten af aftalen skal fortsætte med fuld kraft og virkning.</p>
<p><strong>Ret til at give afkald.</strong> Enhver eller alle rettigheder og begrænsninger anført i denne voldgiftsaftale kan fraviges af den part, mod hvem kravet er fremsat. En sådan fravigelse skal ikke fravige eller påvirke nogen anden del af denne voldgiftsaftale.</p>
<p><strong>Aftalens overlevelse.</strong> Denne voldgiftsaftale vil overleve ophøret af dit forhold til os.</p>
<p><strong>Småkravsdomstol.</strong> Uanset det foregående, kan enten du eller vi anlægge en individuel sag i småkravsdomstolen.</p>
<p><strong>Akut retfærdig afhjælpning.</strong> Uanset det foregående, kan begge parter søge akut retfærdig afhjælpning for en statslig eller føderal domstol for at opretholde status quo i afventning af voldgift. En anmodning om midlertidige foranstaltninger skal ikke betragtes som en afkald på nogen andre rettigheder eller forpligtelser i henhold til denne voldgiftsaftale.</p>
<p><strong>Krav, der ikke er underlagt voldgift.</strong> Uanset det foregående, skal krav om bagvaskelse, overtrædelse af Computer Fraud and Abuse Act, og krænkelse eller misbrug af den anden parts patent, ophavsret, varemærke eller forretningshemmeligheder ikke være underlagt denne voldgiftsaftale.</p>
<p>I alle tilfælde, hvor den foregående voldgiftsaftale giver parterne mulighed for at føre retssag i retten, er parterne hermed enige om at underkaste sig den personlige jurisdiktion hos domstolene i Netherlands County, Californien, til sådanne formål.</p>
<p>Webstedet kan være underlagt amerikanske eksportkontrollove og kan være underlagt eksport- eller importreguleringer i andre lande. Du accepterer ikke at eksportere, geneksportere eller overføre, direkte eller indirekte, nogen amerikanske tekniske data erhvervet fra os, eller nogen produkter, der anvender sådanne data, i strid med De Forenede Staters eksportlove eller -regler.</p>`,
            section8: `<p><strong>Elektronisk kommunikation.</strong> Kommunikation mellem dig og os bruger elektroniske midler, uanset om du bruger webstedet eller sender os e-mails, eller om vi opslår meddelelser på webstedet eller kommunikerer med dig via e-mail. For kontraktmæssige formål, (a) giver du samtykke til at modtage kommunikation fra os i elektronisk form; og (b) accepterer du, at alle vilkår og betingelser, aftaler, meddelelser, information og anden kommunikation, som vi leverer til dig elektronisk, opfylder enhver juridisk forpligtelse, som sådan kommunikation ville opfylde, hvis den var i skriftlig form.</p>
<p><strong>Hele vilkårene.</strong> Disse vilkår udgør hele aftalen mellem dig og os vedrørende brugen af webstedet. Vores manglende udøvelse eller håndhævelse af enhver ret eller bestemmelse i disse vilkår skal ikke fungere som et afkald på sådan ret eller bestemmelse. Afsnitstitlerne i disse vilkår er kun for nemheds skyld og har ingen juridisk eller kontraktmæssig virkning. Ordet "inklusiv" betyder "inklusiv uden begrænsning". Hvis nogen bestemmelse i disse vilkår holdes for ugyldig eller ikke kan håndhæves, vil de øvrige bestemmelser i disse vilkår forblive upåvirkede, og den ugyldige eller ikke-håndhævelige bestemmelse vil blive anset for ændret, så den er gyldig og håndhævelig i videst muligt omfang tilladt ved lov. Dit forhold til os er som en uafhængig entreprenør, og ingen af parterne er agent eller partner for den anden. Disse vilkår og dine rettigheder og forpligtelser heri må ikke overdrages, underentrepriseres, delegeres eller på anden måde overføres af dig uden vores forudgående skriftlige samtykke, og enhver forsøgt overdragelse, underentreprise, delegation eller overførsel i strid med det foregående vil være ugyldig og uden virkning. Vi kan frit tildele disse vilkår. De vilkår og betingelser, der er fastsat i disse vilkår, skal være bindende for erhververe.</p>
<p><strong>Din privatliv.</strong> Læs venligst vores fortrolighedspolitik.</p>
<p><strong>Ophavsret/varemærkeinformation.</strong> Copyright ©. Alle rettigheder forbeholdes. Alle varemærker, logoer og servicemærker vist på webstedet er vores ejendom eller ejendom tilhørende andre tredjeparter. Du har ikke tilladelse til at bruge disse mærker uden vores forudgående skriftlige samtykke eller samtykke fra den tredjepart, der måtte eje mærkerne.</p>
<h2>Kontaktoplysninger</h2>
<p>Email: watch_support@adflash.cn</p>`
        },
        privacy: {
            section1: `<p>At beskytte dit privatliv er vigtigt for os. Vi håber, at følgende erklæring vil hjælpe dig med at forstå, hvordan produkterne håndterer de personligt identificerbare oplysninger, du lejlighedsvis kan give os via internettet.</p>
<p>Baseret på vores apps og tilknyttede tjenester kan du ansøge om brug af en særlig funktionel tjeneste leveret af en tredjepart. Hvis du har brug for at tilmelde dig og bruge en særlig funktionel tjeneste, bedes du omhyggeligt læse servicevilkårene for denne særlige funktionelle tjeneste. Ved at acceptere eller bruge en særlig funktionel tjeneste anerkender du, at du har læst og accepteret denne aftale og servicevilkårene for den særlige funktionelle tjeneste og accepterer at være bundet af dem.</p>
<p>Nogle gange har vi brug for informationer for at levere tjenester, som du anmoder om; denne fortrolighedserklæring gælder for dens produkter.</p>
<h2>Oplysninger, teamet indsamler og modtager.</h2>
<p>Når du bruger softwaren & tjenesterne, kan vi automatisk indsamle og gemme følgende typer oplysninger:</p>
<p>1. Teknisk information. Visse tekniske oplysninger om din enhed og din brug af softwaren & tjenesterne og anden tredjeparts software og applikationer, der er installeret på din enhed ("Anden software"), inklusive typen, navnet og versionen af operativsystemet og Anden software inklusive, uden begrænsning, deres interaktioner med softwaren & tjenesterne, ISP (Internet Service Provider), placering (GPS), internetprotokol (IP)-adresse, anonyme brugeridentifikatorer, enhedskonfiguration og identifikatorer (IDFA (Identifier for Advertisers brugt i iOS), Advertising ID (Identifier for Advertisers brugt i Android) osv.), version af softwaren & tjenesterne og din konfiguration og indstillinger af softwaren & tjenesterne;</p>
<p>2. Brugsinformation. Visse brugsrelaterede oplysninger om din brug og interaktion med din enhed, herunder softwaren & tjenesterne og anden software, som f.eks. hvornår og hvordan du bruger softwaren & tjenesterne og andre software. For eksempel, hvilke applikationer du har, hvilke applikationer du bruger, og hvor ofte du bruger dem. Annoncer, som du ser, bruger og tilgår, hvordan du bruger dem og din respons på dem;</p>
<p>3. Sociale netværk. Dine interaktioner på sociale netværk gennem softwaren & tjenesterne;</p>`,
            section2: `<p>4. Registreringsrelateret information. Oplysninger, du giver, når du registrerer dig til softwaren & tjenesterne (hvis relevant), såsom navn, hjem- eller arbejdsadresser, e-mailadresse, telefon- og faxnumre, fødselsdato og køn;</p>
<p>5. Supportrelateret information. Hvis du kommunikerer med os via vores supportsektion eller e-mail, kan vi også indsamle din e-mailadresse, navn og eventuelle oplysninger, som du frivilligt indsender i forbindelse med support. Når du bruger softwaren & tjenesterne, genererer vi automatisk en anonym intern bruger-ID, som tildeles din brug af softwaren & tjenesterne ("Bruger-ID"), og som kan kombineres med nogen af ovenstående oplysninger.</p>
<p>6. Enhedsoplysninger</p>
<p>Vi indsamler oplysninger om din enhed, herunder hardwaremodel, operativsystem og version, unikke enhedsidentifikatorer (inklusive SSAID, GAID), browsertype og sprog, mobiltelefonnummer og mobilenetværksoplysninger, International Mobile Equipment Identity ("IMEI"), Identifier for Advertising ("IDFA"), Identifier for Vendor ("IDFV"), Integrated Circuit Card Identifier ("ICCID"), Media Access Control ("MAC")-adresse, model og opløsning, som vil blive brugt af os til at beregne antallet af enheder, der bruger vores produkter og tjenester og analysere data om enhedsmodeller og grafiktiminger. Du kan vælge ikke at give visse oplysninger, men så kan du muligvis ikke drage fordel af mange af vores APP'er. Vi indsamler også visse enhedsoplysninger, der vil hjælpe os med at diagnosticere problemer i den (forhåbentlig sjældne) begivenhed, at du oplever et nedbrud eller et andet problem, mens du bruger vores tjenester.</p>
<p>7. Kamera og fotos</p>
<p>Mange af vores tjenester kræver, at vi indsamler billeder og andre oplysninger fra din enheds kamera og fotos. Vi vil kun få adgang til dit kamera og fotos, efter du har givet os dit samtykke. Du kan vælge ikke at give det, men så kan du muligvis ikke drage fordel af mange af vores tjenester.</p>
<p>8. Lokal lagring</p>
<p>Vi kan indsamle og gemme oplysninger lokalt på din enhed ved hjælp af mekanismer såsom browser-weblagring (inklusive HTML 5) og applikationsdatacacher. Oplysningerne, vi gemmer, vil ikke blive delt med nogen tredjepart.</p>
<p>9. IKKE-PERSONLIGE OPLYSNINGER</p>`,
            section3: `<p>IKKE-personlige oplysninger er data i en form, der ikke tillader direkte forbindelse med nogen specifik person, såsom dit Android-ID, CPN-model, hukommelsesstørrelse, dit telefon-IMEI-nummer, telefonmodel, ROM, installeret applikationsnavn og pakkenavn, telefonoperatør, placering, installation, afinstallation, brugsfrekvens osv.</p>
<p>Vi kan indsamle og bruge ikke-personlige oplysninger under følgende omstændigheder. For at få en bedre forståelse af brugerens adfærd, løse problemer i produkter og tjenester, forbedre vores produkter, tjenester og reklamer, kan vi indsamle ikke-personlige oplysninger såsom installeret applikationsnavn og pakkenavn, installationsdata, brugsfrekvens, land, udstyr og kanal.</p>
<h2>Hvordan teamet bruger dine oplysninger?</h2>
<p>Teamet kan bruge de oplysninger, det indsamler, til analytiske, forretningsmæssige og operationelle formål på følgende måder:</p>
<p>1. for at drive og opdatere software & tjenester og give dig eksisterende og nye funktionaliteter og funktioner;</p>
<p>2. for at forbedre og tilpasse software & tjenester;</p>
<p>3. for at levere personaliseret indhold til dig, herunder gennem levering af målrettede interessebaserede annoncer fra os eller vores partnere;</p>
<p>4. for at opretholde og forbedre kvaliteten og driften af software & tjenester;</p>
<p>5. for at underrette dig om opdateringer af software & tjenester;</p>
<p>6. for at yde dig support og håndtere dine klager og/eller feedback;</p>
<p>7. for at tage enhver handling i tilfælde af en tvist, der involverer dig i forbindelse med software & tjenester;</p>`,
            section4: `<p>8. for at håndhæve de vilkår og betingelser, der gælder for softwaren & tjenesterne og for at forhindre ulovlige aktiviteter og misbrug deraf;</p>
<p>9. for at overholde og opfylde enhver gældende lov, regulering, juridisk proces eller håndhævelig regeringsanmodning. Deling af oplysninger med tredjeparter.</p>
<h2>Børns privatliv</h2>
<p>Disse tjenester er ikke rettet mod nogen under 13 år. Jeg indsamler ikke bevidst personligt identificerbare oplysninger fra børn under 13 år. I det tilfælde, jeg opdager, at et barn under 13 år har givet mig personlige oplysninger, sletter jeg disse straks fra vores servere. Hvis du er forælder eller værge og er opmærksom på, at dit barn har givet os personlige oplysninger, bedes du kontakte mig, så jeg kan foretage de nødvendige handlinger.</p>
<h2>Ændringer i denne privatlivspolitik</h2>
<p>Jeg kan opdatere vores privatlivspolitik fra tid til anden. Derfor rådes du til at gennemgå denne side periodisk for eventuelle ændringer. Jeg vil underrette dig om eventuelle ændringer ved at offentliggøre den nye privatlivspolitik på denne side. Disse ændringer træder i kraft straks efter, de er offentliggjort på denne side.</p>
<h2>Kontakt os</h2>
<p>Hvis du har spørgsmål eller forslag til min privatlivspolitik, skal du ikke tøve med at kontakte mig på email: watch_support@adflash.cn</p>`,


        },
        refund: {
            section1: `<p>Tak for dit køb. Hvis du ikke er helt tilfreds med dit køb, vil vi gerne hjælpe dig.</p>
<p>For at starte refusionsprocessen skal du følge nedenstående trin.</p>
<p>Abonnementsprodukter (f.eks. Basisabonnement)</p>
<p>Hvis du stopper med at bruge, men glemmer at annullere dit abonnement, vil vi normalt gerne refundere din seneste abonnementsbetaling, så længe du ikke har brugt nogen sessioner eller oprettet nogen billeder efter betalingen blev foretaget.</p>
<p>For at anmode om en refundering, bedes du sende os en refunderinganmodning gennem din konto og via feedbackformularen. Vi kan blive nødt til at bede dig om specifikke oplysninger for at hjælpe os med at bekræfte din identitet.</p>
<p>Digitale produkter (f.eks. Tids pakker, abonnementer)</p>
<p>Der vil ikke blive givet refunderinger, hvis nogen tids pakker og abonnementer er blevet brugt. Vi tilbyder ikke delvise eller forholdsmæssige refunderinger.</p>
<p>Du har 14 kalenderdage fra transaktionsdatoen til at anmode om en refundering.</p>
<p>Når vi modtager din refunderinganmodning, vil vores personale tjekke din medlemsstatus for at afgøre, om du er berettiget til en refundering.</p>`,
            section2: `<p>Hvis du bliver godkendt, vil din refundering blive behandlet. Refunderingen vil blive krediteret til den oprindelige [betalingsmetode], der blev brugt ved købet.</p>
<p>Forsinkede eller manglende refunderinger</p>
<p>Hvis du ikke har modtaget din refundering, bedes du først tjekke din bankkonto igen.</p>
<p>Kontakt derefter dit bankkortfirma, det kan tage noget tid, før din refundering officielt frigives.</p>
<p>Kontakt derefter din bank. Det tager normalt noget behandlingstid, før refunderingen frigives.</p>
<p>Hvis du har gjort alt dette og stadig ikke har modtaget din refundering, bedes du kontakte os på watch_support@adflash.cn</p>
<p>Salg genstande</p>
<p>Kun regulære prisgenstande kan refunderes, desværre kan salgsgenstande ikke refunderes.</p>`,
        },
        us: {
            section1: `<p>Firmaoplysninger</p>
<p>Navn: HONG KONG ADFLASH MEDIA LIMITED</p>
<p>Adresse: RM 1101 11/F SAN TOI BUILDING NO.139 CONNAUGHT ROAD CENTRAL HK</p>
<p>Email: watch_support@adflash.cn</p>`,
        },
    }
}