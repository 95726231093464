import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import errorDialog from '@/components/errorDialog.vue' // 错误弹窗



Vue.component(CollapseTransition.name, CollapseTransition);
Vue.component('error-dialog', errorDialog);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import './assets/element-variables.scss'

// 弹出层
import 'vant/lib/index.css';
import { Popup } from 'vant';
Vue.use(Popup);

import { Toast } from 'vant';
Vue.use(Toast);

import axios from 'axios'
Vue.prototype.$axios= axios


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  i18n,
  store,
}).$mount('#app')
